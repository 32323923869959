import React, { useContext, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";
import { Button, Skeleton } from "antd";
import { FaExpandAlt, FaPlay } from "react-icons/fa";
import ReactAudioPlayer from "react-audio-player";

import temp from "../../../../images/56f4b8301c915c89bdfb278a9b9f6bbb.png";
import { BsArrowRightCircleFill } from "react-icons/bs";
import Paragraph from "antd/es/typography/Paragraph";

export default function Free() {
  const { editor, seteditor, user } = useContext(SearchContext);
  const [p, setP] = useState();

  return (
    <div className="videos-left-ed" onClick={() => console.log(editor)}>
      {editor.free &&
        editor.free.map((g, gi) => {
          return (
            <div
              key={gi}
              style={{
                width: "100%",
                marginRight: "0px",

                height: "auto",
              }}
              className="video-card-story"
            >
              {p && p._id === g._id ? (
                <div className="video-container video-container-round">
                  <p>{g.query}</p>

                  <ReactAudioPlayer src={g.file_url} autoPlay controls />
                  <div className="show-content-icon video-ixn">
                    <BsArrowRightCircleFill
                      onClick={() =>
                        seteditor({
                          ...editor,
                          activeContent: g,
                          activeType: "sound",
                        })
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="sound-thumbnail-img">
                  {g.quene === "progress" ? (
                    <div style={{ background: "grey", borderRadius: "5px" }}>
                      <Skeleton.Button
                        className="eighty-w-sk"
                        active
                        size={80}
                        shape={"default"}
                      />
                    </div>
                  ) : (
                    <img
                      className=""
                      src={g.root_image ? g.root_image : temp}
                      alt=""
                    />
                  )}
                  <div className="sound-thmb-qa">
                    <Paragraph
                      ellipsis={{
                        rows: 2,
                        // expandable: true,
                        symbol: "see more",
                      }}
                      className="desc-p-crdjjkl"
                    >
                      {g.description}
                    </Paragraph>

                    <div className="bpttpm-icn-wc">
                      <FaPlay onClick={() => setP(g)} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}
