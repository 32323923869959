import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Modal, Slider, message } from "antd";
import { FaEraser, FaPaintBrush, FaViadeo, FaVideo } from "react-icons/fa";
import SearchContext from "../../../../Context/SearchContext";
import ReactPlayer from "react-player";
export default function DrawImg() {
  const canvasRef = useRef(null);
  const { editor, setEditor, user } = useContext(SearchContext);
  const [data, setData] = useState();
  const [color, setColor] = useState("white");
  const [drawing, setDrawing] = useState(false);
  const [brushSize, setBrushSize] = useState(50);
  const [startRegion, setStartRegion] = useState(null);
  const [isErasing, setIsErasing] = useState(false);
  const [search, setSearch] = useState("");
  const [strokes, setStrokes] = useState([]);
  const [taskId, setTaskID] = useState();
  const [callApi, setCallApi] = useState(1);
  const [imgLoading, setImgLoading] = useState(false);

  useEffect(() => {
    if (taskId) {
      getTasks(taskId);
    }
  }, [callApi]);
  const getRegion = (x, y) => {
    const canvas = canvasRef.current;
    const widthQuarter = canvas.width / 4;
    const heightQuarter = canvas.height / 4;
    const column =
      x < widthQuarter
        ? "left"
        : x < 2 * widthQuarter
        ? "centerLeft"
        : x < 3 * widthQuarter
        ? "centerRight"
        : "right";
    const row =
      y < heightQuarter
        ? "top"
        : y < 2 * heightQuarter
        ? "upperMiddle"
        : y < 3 * heightQuarter
        ? "lowerMiddle"
        : "bottom";
    setStrokes([...strokes, `${row}-${column}`]);
    message.info(`${row}-${column}`);

    return `${row}-${column}`;
  };
  const startDrawing = (event) => {
    setDrawing(true);
    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const region = getRegion(x, y);
    setStartRegion(region);
    message.info(region);
    draw(event);
  };
  const draw = (event) => {
    if (!drawing) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    if (!context) return;
    context.lineWidth = brushSize;
    context.lineCap = "round";
    context.strokeStyle = isErasing ? "white" : color;
    if (!context.moveTo) {
      context.beginPath();
    } else {
      context.lineTo(x, y);
      context.stroke();
    }
  };
  const endDrawing = (event) => {
    setDrawing(false);
    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const endRegion = getRegion(x, y);
    if (startRegion === endRegion) {
      console.log(`${startRegion} stroke`);
    } else {
      console.log("Stroke moved across regions");
    }
  };
  const toggleEraser = () => {
    setIsErasing(!isErasing);
    setColor(isErasing ? "blue" : "white"); // Adjust colors as needed
  };
  const handleBrushSizeChange = (value) => {
    setBrushSize(value);
  };

  const createVideo = () => {
    const obj = {
      stroke: strokes,
      brush_size: brushSize,
      query: search,
      user_id: user && user.user_id,
      preview_id: editor.activeContent?.img_id,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/create/video/brush`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          setTaskID(response.data._id);
          setTimeout(() => {
            setCallApi(callApi + 1);
          }, 5000);
          //    setDisplayVid(response.imageData)
        } else {
          // message.error("somehing went wrong");
        }
      });
  };
  const getTasks = (ID) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(`https://embed.axv.ai/api/image/${ID}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          if (data.imageData.quene === "progress") {
            setCallApi(callApi + 1);
          } else if (data.imageData.quene === "complete") {
            setEditor({
              ...editor,
              activeContent: {
                ...editor.activeContent,
                paintVideo: data.imageData,
              },
            });
            setTaskID();
            message.success("created");
          }
        } else {
          setTaskID();
          message.error("failed try again");
        }
      });
  };
  return (
    <>
      <FaPaintBrush onClick={() => setData(editor.activeContent)} />
      <Modal
        open={data !== undefined}
        onCancel={() => {
          setData();
        }}
        width={1064}
        className="no-buttons-modal black-modal"
      >
        <div className="center-input-u-img">
          <input
            style={{
              width: 300,
            }}
            type="text"
            placeholder="Search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            value={search}
            required=""
            className="form-control border-0 shadow-sm px-4 "
          />
          {search.trim().length ? (
            <div
              onClick={createVideo}
              style={{ padding: "0px", width: "auto" }}
              className={` start-card-bx `}
            >
              <div style={{ padding: "8px" }} className="icon-planing-c">
                <FaVideo />
              </div>
            </div>
          ) : null}
        </div>
        <Button onClick={toggleEraser}>
          {isErasing ? <FaPaintBrush /> : <FaEraser />}
          {isErasing ? "Switch to Brush" : "Switch to Eraser"}
        </Button>
        {data ? (
          <div className="img-bx-draw">
            <img src={data.file_url2} alt="" />
            <canvas
              ref={canvasRef}
              className="canvas-drwa-img"
              width={1024}
              height={512}
              onMouseDown={startDrawing}
              onMouseMove={(event) => drawing && draw(event)}
              onMouseUp={endDrawing}
              onMouseLeave={endDrawing}
            />
          </div>
        ) : null}
        <div className="brush-size-d">
          <b>Brush Size:</b>
          <Slider
            min={1}
            max={50}
            style={{ width: "200px" }}
            defaultValue={brushSize}
            onChange={handleBrushSizeChange}
          />
        </div>
        {editor.activeContent?.paintVideo ? (
          <div className="video-container video-container-round">
            <ReactPlayer playing url={editor.activeContent?.paintVideo.file_url} controls />
          </div>
        ) : null}
      </Modal>
    </>
  );
}
