import React, { useContext, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";
import { Button, Skeleton, Tooltip, message } from "antd";
import { FaExpandAlt, FaPlay } from "react-icons/fa";
import ReactAudioPlayer from "react-audio-player";

import temp from "../../../../images/56f4b8301c915c89bdfb278a9b9f6bbb.png";
import { BsArrowRightCircleFill } from "react-icons/bs";
import moment from "moment";
import { Navigate, useNavigate } from "react-router-dom";
import { MdClose } from "react-icons/md";

export default function Projects() {
  const navigate = useNavigate();
  const { editor, setStories, stories, storyChange, setStoryChange } =
    useContext(SearchContext);
  const [p, setP] = useState();
  const deleteTask = (row, key) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/project/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
          const all = editor.images.filter((p) => p._id !== row._id);
          // seteditor({ ...editor, images: all });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };
  return (
    <div className="videos-left-ed" onClick={() => console.log(editor)}>
      {editor.projects &&
        editor.projects.map((g, gi) => {
          return (
            <div
              key={gi}
              style={{
                width: "100%",
                marginRight: "20px",

                height: "auto",
              }}
              className={`video-card-story  projects-con ${
                g._id === stories.active?._id ? " active-card-pro" : ""
              }`}
              onClick={() => {
                navigate(`/canvas/project/${g._id}`);
                setStories({ ...stories, active: g, data:undefined });
           
                setStoryChange(!storyChange);
              }}
            >
              <div className="sound-thumbnail-img">
                <Tooltip title="Delete">
                  <div
                    style={{ width: "14px", height: "14px", opacity: "1" }}
                    onClick={() => {
                      deleteTask(g);
                    }}
                    className="icon-img-hover"
                  >
                    <MdClose style={{ fontSize: "14px", color: "black" }} />
                  </div>
                </Tooltip>
                <div className="sound-thmb-qa">
                  <p>{g.project_name}</p>

                  <div style={{ fontSize: "12px" }} className="bpttpm-icn-wc">
                    {moment(g.createdAt).fromNow()}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}
