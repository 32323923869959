import React, { useContext, useEffect, useRef, useState } from "react";
import SearchContext from "../../../Context/SearchContext";
import Sound from "./contents/Sound";
import Start from "./contents/Start";
import ImageBlank from "./contents/ImageBlank";
import VideoBlank from "./contents/VideoBlank";
import axios from "axios";
import Slider from "react-slick";
import { FaArrowLeft, FaArrowRight, FaMagic, FaPaintBrush } from "react-icons/fa";
import ReactPageScroll from "react-page-scroll";
import PageScroll from "react-page-scroll";
import FullLoader from "../../storyProject/components/FullLoader";
import TopBar from "./contents/TopBar";
import { Tooltip } from "antd";
import { BiBookBookmark, BiLibrary } from "react-icons/bi";
import { BsFillChatDotsFill } from "react-icons/bs";
import RightIcons from "./contents/RightIcons";
export default function Content() {
  const scrolRef = useRef();
  const {
    editor,
    seteditor,
    user,
    storyChange,
    setStoryChange,
    setStories,
    stories,
  } = useContext(SearchContext);
  const [loading, setloading] = useState(true);
  const [scrolChange, setscrolChange] = useState();

  useEffect(() => {
    if (window.location.pathname !== "/canvas/new") {
      if (stories.active === undefined) {
        getProject(window.location.pathname.slice(16));
      } else {
        setloading(true);
        getGenerated(window.location.pathname.slice(16), stories.active);
      }
    } else {
      setloading(false);
    }
  }, [storyChange]);

  useEffect(() => {
    if (scrolChange !== undefined &&stories.data.length) {
      console.log("scrolRef");
      seteditor({
        ...editor,
        activeContent: stories.data[scrolChange]
          ? stories.data[scrolChange]
          : {},
        activeType: stories.data[scrolChange]?.media_type
          ? stories.data[scrolChange].media_type
          : editor.activeType,
        position: scrolChange,
      });
    }
  }, [scrolChange]);
  const getProject = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(`https://embed.axv.ai/api/project/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          getGenerated(id, data.project);
        }
      });
  };
  const getGenerated = async (id, activePro) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(
        `https://embed.axv.ai/api/image/?page=1&limit=20&sort_by=${-1}&user_id=${
          user && user.user_id
        }&media_type=&project_id=${id}`
      );
      if (response.data && response.data.imageList) {
        setStories({
          ...stories,
          data: response.data.imageList.sort((a, b) => a.position - b.position),
          active: activePro,
        });
        let active = response.data.imageList.length
          ? response.data.imageList[0]
          : undefined;
        seteditor({
          ...editor,
          activeContent: active,
          activeType: active
            ? active.media_type === "original"
              ? "video"
              : active.media_type
            : undefined,
          position: 0,
        });
      }
      setloading(false);
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };

  return loading ? (
    <FullLoader />
  ) : stories.data &&stories.data.length? (
    <div  style={{ width: "100%", height: "100vh", position: "relative" }}>
      <div className="top-bar-page-c">
        <TopBar />
      </div>
<RightIcons />
      <PageScroll
        onScrollStart={(e) => {
          // console.log(e.targetIndex, editor);

          if (e.targetIndex < stories.data.length) {
            setscrolChange(e.targetIndex);
          }
        }}
        ref={scrolRef}
        width="100%"
        height="100vh"
      >
        {stories.data &&
          stories.data.map((d, di) => {
            return (
              <div
                onClick={() =>
                  console.log( editor.activeType, stories.data)
                }
                key={di}
                id="page1"
                className="page"
              >
                <div style={{ paddingTop: "50px" }} className="content-editor">
                  {editor.activeContent && editor.activeType === "image" ? (
                    <ImageBlank data={d} />
                  ) : null}
                  {editor.activeContent && editor.activeType === "video" ? (
                    <VideoBlank />
                  ) : null}
                </div>
              </div>
            );
          })}
        {editor.activeContent === undefined ? (
          <div className="content-editor">
            <Start />
          </div>
        ) : null}
      </PageScroll>
    </div>
  ) : (
    <div className="content-editor">

      <Start />



      {/* {editor.activeContent && editor.activeType === "sound" ? <Sound /> : null}
      {editor.activeContent && editor.activeType === "audio blank" ? (
        <ImageBlank />
      ) : null} */}
      {/* {editor.activeContent
        ? stories.data &&
          stories.data.map((s, si) => {
            return (
              <div>
                {s.media_type === "image" ? <ImageBlank /> : <ImageBlank />}
                <div className="arrow-pro-c">
                  <FaArrowLeft />
                  <FaArrowRight />
                </div>
              </div>
            );
          })
        : null} */}
    </div>
  );
}
