import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";
import ReactPlayer from "react-player";
import { Button, Skeleton, Spin, Tag, Tooltip, message } from "antd";
import { FaExpandAlt, FaPlay } from "react-icons/fa";
import temp from "../../../../images/56f4b8301c915c89bdfb278a9b9f6bbb.png";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { LoadingOutlined } from "@ant-design/icons";

import { MdClose } from "react-icons/md";
import axios from "axios";
export default function Videos() {
  const { editor, seteditor, user } = useContext(SearchContext);
  const [p, setP] = useState();
  const [callApi, setCallApi] = useState(1);
  const [loading2, setLoading2] = useState(false);

  useEffect(() => {
    if (editor.vPageNo > 1 && callApi > 1) {
      getVideoGenerated();
    }
  }, [callApi]);
  const deleteTask = (row, key) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
          const all = editor.videos.filter((p) => p._id !== row._id);
          seteditor({ ...editor, videos: all });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };
  const loadMore = (v, key) => {
    // console.log(
    //   v.target.scrollHeight,
    //   "sss",
    //   Math.ceil(v.target.offsetHeight + v.target.scrollTop),
    //   !loading2,editor.totalPages , editor.vPageNo
    // );
    if (
      v.target.scrollHeight ===
        Math.ceil(v.target.offsetHeight + v.target.scrollTop) &&
      editor.totalPages > editor.vPageNo &&
      !loading2
    ) {
      // console.log("load")
      seteditor({
        ...editor,
        vPageNo: editor.vPageNo + 1,
        scrollHeight: v.target.scrollHeight, // for later use for fix scrol height
      });
      setLoading2(true);
      setCallApi(callApi + 1);
    }
  };
  const getVideoGenerated = async (isThunder) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(
        `https://embed.axv.ai/api/image/?page=${
          editor.vPageNo
        }&limit=20&sort_by=${-1}&user_id=${
          user && user.user_id
        }&media_type=original&status=generated`
      );
      setLoading2(false);
      // Update the state with the retrieved jobs data
      let all = editor.videos.concat(response.data?.imageList);
      seteditor({
        ...editor,
        videos: all,
        totalPagesV: response.data?.totalPages,
      });
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color:"white"
      }}
      spin
    />
  );
  return (
    <div onScroll={loadMore} className="videos-left-ed">
      {editor.videos &&
        editor.videos.map((g, gi) => {
          return (
            <div
              key={gi}
              style={{
                width: p && p._id === g._id ? "100%" : "140px",
                marginRight: p && p._id === g._id ? "0px" : "10px",
                height: "auto",
              }}
              className="video-card-story"
            >
              {g.quene === "progress" ? (
                <div style={{ background: "grey", borderRadius: "5px" }}>
                  <Skeleton.Button active size={115} shape={"default"} />
                </div>
              ) : p && p._id === g._id ? (
                <div className="video-container video-container-round">
                  <div
                    style={{ top: "-5px", right: "-10px" }}
                    className="show-content-icon2 show-hover"
                  >
                    <span />
                    <Tooltip title="Delete">
                      <div
                        style={{ width: "14px", height: "14px", opacity: "1" }}
                        onClick={() => deleteTask(g)}
                        className="icon-img-hover"
                      >
                        <MdClose style={{ fontSize: "14px", color: "black" }} />
                      </div>
                    </Tooltip>
                  </div>
                  <ReactPlayer
                    controls
                    playing
                    url={g.file_url}
                    config={{
                      file: {
                        attributes: {
                          controlsList: "nodownload",
                        },
                      },
                    }}
                  />
                  <div className="show-content-icon video-ixn">
                    <BsArrowRightCircleFill
                      onClick={() =>
                        seteditor({
                          ...editor,
                          activeContent: g,
                          activeType: "video",
                        })
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="video-thumbnail-img">
                  <div
                    style={{ top: "-5px", right: "-10px" }}
                    className="show-content-icon2 show-hover"
                  >
                    <span />
                    <Tooltip title="Delete">
                      <div
                        style={{ width: "14px", height: "14px", opacity: "1" }}
                        onClick={() => deleteTask(g)}
                        className="icon-img-hover"
                      >
                        <MdClose style={{ fontSize: "14px", color: "black" }} />
                      </div>
                    </Tooltip>
                  </div>
                  <img src={g.root_image ? g.root_image : temp} alt="" />
                  <div className="icons-bx-ri-vd2">
                    <span className="play-blur-glow">
                      <Button
                        onClick={() => setP(g)}
                        size="large"
                        className="large-play-btn"
                      >
                        <FaPlay className="r-atc-incs" />
                      </Button>
                    </span>
                  </div>
                  <div className="show-content-icon2 show-hover">
                    <span />
                    <div
                      onClick={() =>
                        seteditor({
                          ...editor,
                          activeContent: g,
                          activeType: "video",
                        })
                      }
                      className="icon-img-hover"
                    >
                      <BsArrowRightCircleFill />
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      <div className="loadin-videos-more-c">{loading2 ? <Spin indicator={antIcon} /> : null}</div>
    </div>
  );
}
