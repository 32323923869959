import React, { useRef, useState } from "react";
import SearchContext from "./SearchContext";

const SearchState = (props) => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const [chat, setChat] = useState(1);
  const [images, setImages] = useState({ pageNo: 1, pageNoV: 1 });
  const [wc, setWC] = useState({});
  const [editor, seteditor] = useState({
    cType: "canvas",
    width: 80,
    vPageNo: 1,
    iPageNo: 1,
    sPageNo: 1,
    voPageNo: 1,
    bPageNo: 1,
    cnPageNo: 1,
  });
  const [editorLeft, seteditorLeft] = useState(1);
  const [assist, setassist] = useState({
    pageNo: 1,
    activeTab: "Guide",
    demo_id: "store_demo",
  });
  const [user, setUser] = useState();
  const [right, setRight] = useState({});
  const [theme, setTheme] = useState("blue");
  const [left, setLeft] = useState({});
  const [generate, setGenerate] = useState({
    pageNo1: 1,
    pageNo2: 1,
    pageNo3: 1,
  });
  const [load, setLoad] = useState();
  const [incident, setincident] = useState();
  const [loginChange, setLoginChange] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [organization, setOrganization] = useState();
  const [formType, setFormType] = useState();
  const [afterSU, setAfterSU] = useState();
  const [loading, setLoading] = useState(true);
  const [callApi, setCallApi] = useState(1);
  const [displayVid, setDisplayVid] = useState();
  const [profile, setProfile] = useState({ pageNo: 1 });
  const [stories, setStories] = useState({ pageNo: 1 });
  const [storyChange, setStoryChange] = useState(false);
  const [pActive, setPActive] = useState();
  const [type, setType] = useState("image");
  const [callOrg, setCallOrg] = useState(1);
  const [language, setLanguage] = useState("english");
  const [videosFeed, setVideosFeed] = useState([]);
  const [horizontalCards, setHorizontalCards] = useState([]);
  const [generated, setGenerated] = useState();

  const resetAll = () => {
    setImages({ pageNo: 1, pageNoV: 1 });
    setLoginChange(loginChange + 1);
    setGenerate({ pageNo1: 1, pageNo2: 1, pageNo3: 1 });
    setRight({});
    setChat({
      feed: chat.feed,
      pageNo: 1,
      activeTab: "Guide",
      demo_id: "store_demo",
    });
    setassist({
      pageNo: 1,
      activeTab: "Guide",
      demo_id: "store_demo",
    });
  };
  return (
    <SearchContext.Provider
      value={{
        ref1,
        ref2,
        ref3,
        ref4,
        ref5,
        storyChange,
        setStoryChange,
        wc,
        setWC,
        editor,
        seteditor,
        editorLeft,
        seteditorLeft,
        generated,
        setGenerated,
        horizontalCards,
        setHorizontalCards,
        stories,
        setStories,
        showPrice,
        setShowPrice,
        videosFeed,
        setVideosFeed,
        language,
        setLanguage,
        left,
        setLeft,
        theme,
        setTheme,
        setCallOrg,
        callOrg,
        type,
        setType,
        generate,
        setGenerate,
        pActive,
        setPActive,
        profile,
        setProfile,
        displayVid,
        setDisplayVid,
        callApi,
        setCallApi,
        afterSU,
        setAfterSU,
        loading,
        setLoading,
        formType,
        setFormType,
        resetAll,
        loginChange,
        setLoginChange,
        organization,
        setOrganization,
        incident,
        setincident,

        load,
        setLoad,
        right,
        setRight,
        user,
        setUser,
        assist,
        setassist,
        images,
        setImages,
        chat,
        setChat,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};
export default SearchState;
