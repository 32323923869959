import { EllipsisOutlined } from "@ant-design/icons";
import { Button, Divider, Space, Tour } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import SearchContext from "../Context/SearchContext";
import { useLocation } from "react-router-dom";
const TourOnboard = () => {
  const { ref1, ref2, ref3, ref4, ref5, organization, setOrganization } =
    useContext(SearchContext);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === "/studio" &&
      organization &&
      organization.onboard === "tour" &&
      organization.onboardStep === undefined
    ) {
      setOrganization({ ...organization, onboardStep: 1 });
      setOpen(true);
    }
  }, [organization, location]);

  const updateOrganization = (key, val) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append(key, val);

    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {});
  };

  const handleNextClick = () => {
    console.log("ss");
  };
  const steps = [
    {
      title: "Query",
      description:
        "Here is where you give a description of the video you want to create.",

      target: () => ref1.current,
    },
    {
      title: "Duration",
      description:
        "Select how long you want the created video to be. Videos can be up to 1 min long.",

      target: () => ref2.current,
    },
    {
      title: "Imagine",
      description:
        "When you’ve decided on your parameters, click this imagine button to start the creation process.",

      target: () => ref3.current,
    },
    {
      title: "Status",
      description:
        "Your generation requests will appear here with either a complete, in progress or failed icon.",

      target: () => ref4.current,
    },
    {
      title: "Creation",
      description:
        "Your final video will appear here. Videos generally take between 2 and 6 minutes depending on your plan and compute availability.",

      target: () => ref5.current,
    },
  ];
  return (
    <>
      {organization &&
      organization.onboard !== "complete" &&
      location.pathname === "/studio" ? (
        <Tour
          onFinish={() => {
            updateOrganization("onboard", "complete");
            setOrganization({ ...organization, onboard: "complete" });
          }}
          open={organization && organization.onboard === "tour"}
          // onClose={() => setOpen(false)}
          steps={steps}
          onChange={(e) => setOrganization({ ...organization, onboardStep: e })}
        />
      ) : null}
    </>
  );
};
export default TourOnboard;
