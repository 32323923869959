import { Button, Input, Modal } from "antd";
import React, { useContext, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";
import { useNavigate } from "react-router-dom";

export default function SaveCanvas() {
  const navigate = useNavigate();
  const { user, stories, setStories } = useContext(SearchContext);

  const [dispaly, setDisplay] = useState();
  const [name, setName] = useState("");

  const saveCreate = () => {
    const obj = { project_name: name, user_id: user && user.user_id };
    setDisplay();
    setName("");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/project`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isCreated) {
          navigate(`/canvas/project/${data.project_payload._id}`);
          setStories({...stories, active:data.project_payload})
        }
      });
  };
  return (
    <>
      <Button
        onClick={() => setDisplay(true)}
        className={`editor-tag-tab active-tg-wdt save-btn-c`}
      >
        Save Canvas
      </Button>
      <Modal
        open={dispaly}
        onCancel={() => {
          setDisplay();
          setName("");
        }}
        width={500}
        className="no-buttons-modal black-modal"
      >
        <h4>Project Name</h4>

        <Input
          placeholder="Project Name"
          value={name}
          className="form-control   "
          onChange={(e) => {
            setName(e.target.value);
          }}
          style={{ borderRadius: "20px" }}
        />

        {name.length ? (
          <div className="create-btn-pro">
            <Button type="primary" onClick={saveCreate}>
              Create
            </Button>
          </div>
        ) : null}
      </Modal>
    </>
  );
}
