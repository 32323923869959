import React, { useContext } from "react";
import Header from "../../components/Header";
import ReactPlayer from "react-player";
import SearchContext from "../../Context/SearchContext";
import vid from "../../images/Mountains_34d.mp4";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const { user } = useContext(SearchContext);
  const navigate = useNavigate();
  // Check if user.name exists and redirect accordingly
  if (user && user.name) {
    window.location.href = "/watch";
    return null; // This line ensures that the component doesn't render anything
  }

  return (
    <div>
      <Header />
      <div
        className="container my-9"
        style={{ fontFamily: "Inter, sans-serif" }}
      >
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center align-items-center">
            <div className="col-lg-6">
              <div
                className="p-3 mt-1 text-center"
                style={{ marginBottom: "0px" }}
              >
                <h1
                  className="display-4"
                  style={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  Ideas Are All You Need
                </h1>
                <p
                  className="lead"
                  style={{ fontSize: "1rem", color: "white" }}
                >
                  Generate new videos from scratch in minutes and be inspired
                  from a community of creators
                </p>
              </div>
              <div
                className="text-center rounded-video-container"
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  overflow: "hidden",
                  borderRadius: "10%",
                }}
              >
                <ReactPlayer
                  url={vid}
                  loop
                  playing
                  controls={false}
                  width="100%"
                  height="50%"
                />
              </div>

              <div className="mt-4 text-center">
                <button
                  className="custom-button"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    if (user && user.name) {
                      navigate("/watch");
                    } else {
                      navigate("/watch/try");
                    }
                  }}
                >
                  Watch
                </button>
                <button
                  className="custom-button"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    if (user && user.name) {
                      navigate("/studio");
                    } else {
                      navigate("/login");
                    }
                  }}
                >
                  Studio
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
