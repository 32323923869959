import {
  Alert,
  AutoComplete,
  Input,
  Select,
  Spin,
  Tooltip,
  notification,
} from "antd";
import React, { useContext, useState } from "react";
import { BiSolidImage, BiTime } from "react-icons/bi";
import SearchContext from "../../../Context/SearchContext";
import { RiChatVoiceFill } from "react-icons/ri";
import { IoVideocam } from "react-icons/io";
import { FaMusic, FaPlay, FaVideo } from "react-icons/fa";

import axios from "axios";
import { BsFillCameraVideoFill, BsSoundwave } from "react-icons/bs";
import { MdClose } from "react-icons/md";

export default function Imagine({
  setGeneratedVID,
  displayCreate,
  setDisplayCreate,
  generated,
}) {
  const { user, assist, setShowPrice, organization } =
    useContext(SearchContext);
  const [voicer, setVoicer] = useState("es_speaker_1");

  const [textField, setTextField] = useState(""); // State to store text input
  const [duration, setDuration] = useState(2); // State to store duration input
  const [generatedID, setGeneratedID] = useState();
  const [options, setOptions] = useState([]);
  const [loading, setloading] = useState(false);
  const [voice, setVoice] = useState(false);
  const [cType, setCtype] = useState();
  const [callApi, setCallApi] = useState(1);
  const [callStyle, setCallStyle] = useState(1);
  const [startT, setStartT] = useState(0);
  const [endT, setEndT] = useState(10);
  const { Option } = Select; // Destructure Option from Select
  const [style, setStyle] = useState(""); // Add style state
  const [modalVisible, setModalVisible] = useState(false);
  const [styles, setStyles] = useState([]);
  const [selectedStyle, setSelectedStyle] = useState("");
  const [search, setSearch] = useState("");
  const [searchST, setSearchST] = useState("");
  const [suggestoins, setSuggestoins] = useState();
  const [reimagine, setReimagine] = useState();
  const [isQuick, setIsQuick] = useState();

  const getQueries = (v) => {
    const obj = { query: v };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/topic/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          let op = [];
          for (let i = 0; i < data.length; i++) {
            let optn = { value: data[i].query, id: data[i]._id };
            op.push(optn);
          }
          setOptions(op);
          setSuggestoins(data);
        }
      });
  };

  const handleSendClick = async (srch, isThunder) => {
    const selectedStyleObject = styles.find(
      (styleObj) => styleObj.name === selectedStyle
    );
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/video",
        {
          query: srch ? srch.query : textField,
          user_id: user && user.user_id,
          stream_id: srch && srch._id,
          stream: srch ? "init" : undefined,
          duration: duration,
          voice,
          username: user && user.username,
          profile_picture: user && user.profile_picture,
          //   source_image: assist?.selAssetID?._id,
          style: selectedStyleObject?.metaname,
          project_id: window.location.pathname.slice(7),
        }
      );

      // let videosGen = JSON.parse(window.localStorage.getItem("videos-gen"));

      // videosGen = videosGen ? videosGen : [];
      // videosGen.push(response.data.data);
      // window.localStorage.setItem("videos-gen", JSON.stringify(videosGen));
      // Handle the response here if needed
      // if (srch) {
      //   setSuggestoins();
      // }
      setloading(false);
      // Display the alert
      if (isThunder === "thunder") {
        setGeneratedVID(response.data);
      }
    } catch (error) {
      // Handle any errors here
      if (
        error?.response?.status === 429 &&
        error?.response?.statusText === "Too Many Requests"
      ) {
        setShowPrice(true);
      }
      if (error?.response?.status === 500) {
        notification.warning({
          message: "Systems are overloaded right now. Please wait",
        });
      }
    }
  };
  const handleSendClick3 = async (srch) => {
    let query = srch ? srch.query : textField;
    if (query.trim().length === 0) {
      return;
    }
    let obj = {
      query: srch ? srch.query : textField,
      user_id: user && user.user_id,
      duration: 6,
    };
    setTextField("");
    setSuggestoins();
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/sound",
        obj
      );
      // Handle the response here if needed
      // if (srch) {
      //   setSuggestoins();
      // }
      setloading(false);
      // Display the alert
    } catch (error) {
      // Handle any errors here
      if (
        error?.response?.status === 429 &&
        error?.response?.statusText === "Too Many Requests"
      ) {
        setShowPrice(true);
      }
      if (error?.response?.status === 500) {
        notification.warning({
          message: "Systems are overloaded right now. Please wait",
        });
      }
    }
  };

  const handleSendClick5 = async (srch) => {
    let query = srch ? srch.query : textField;
    if (query.trim().length === 0) {
      return;
    }
    let obj = {
      query: srch ? srch.query : textField,
      user_id: user && user.user_id,
      duration: 6,
    };
    setTextField("");
    setSuggestoins();
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/music",
        obj
      );
      // Handle the response here if needed
      // if (srch) {
      //   setSuggestoins();
      // }
      setloading(false);
      // Display the alert
    } catch (error) {
      // Handle any errors here
      if (
        error?.response?.status === 429 &&
        error?.response?.statusText === "Too Many Requests"
      ) {
        setShowPrice(true);
      }
      if (error?.response?.status === 500) {
        notification.warning({
          message: "Systems are overloaded right now. Please wait",
        });
      }
    }
  };
  const handleSendClick4 = async (srch) => {
    let query = srch ? srch.query : textField;
    if (query.trim().length === 0) {
      return;
    }
    let obj = {
      query: srch ? srch.query : textField,
      user_id: user && user.user_id,
      template: voicer,
    };
    setTextField("");
    setSuggestoins();
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/voice",
        obj
      );
      setloading(false);
    } catch (error) {
      // Handle any errors here
      if (
        error?.response?.status === 429 &&
        error?.response?.statusText === "Too Many Requests"
      ) {
        setShowPrice(true);
      }
      if (error?.response?.status === 500) {
        notification.warning({
          message: "Systems are overloaded right now. Please wait",
        });
      }
    }
  };
  const handleSendClick2 = async (srch) => {
    let query = srch ? srch.query : textField;
    if (query.trim().length === 0) {
      return;
    }
    let obj = {
      query: srch ? srch.query : textField,
      user_id: user && user.user_id,
    };
    setTextField("");
    setSuggestoins();
    setloading(false);
    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://embed.axv.ai/api/create/image",
        obj
      );
      // Handle the response here if needed
      // if (srch) {
      //   setSuggestoins();
      // }

      // Display the alert
    } catch (error) {
      // Handle any errors here
      if (
        error?.response?.status === 429 &&
        error?.response?.statusText === "Too Many Requests"
      ) {
        setShowPrice(true);
      }
      if (error?.response?.status === 500) {
        notification.warning({
          message: "Systems are overloaded right now. Please wait",
        });
      }
    }
  };

  return cType ? (
    <div style={{ padding: "0px" }} className="create-tab">
      <b className="ctype-selected">
        {cType === "music" ? (
          <FaMusic />
        ) : cType === "voice" ? (
          <RiChatVoiceFill />
        ) : cType === "video" ? (
          <BsFillCameraVideoFill />
        ) : cType === "image" ? (
          <BiSolidImage />
        ) : (
          <BsSoundwave />
        )}{" "}
        <MdClose className="close-icon-top-s" onClick={() => setCtype()} />
      </b>

      <AutoComplete
        options={cType === "video" ? options : []}
        style={{
          width: 300,
        }}
        allowClear
        value={textField}
        onSelect={(v) => {
          setloading(true);
          handleSendClick(
            suggestoins && suggestoins.filter((f) => f.query === v)[0],
            "thunder"
          );

          setIsQuick(v);
          setSuggestoins();
          setTimeout(() => {
            setIsQuick();
            // getVideos(window.location.pathname.slice(7), "thunder");
          }, 5000);
        }}
        onSearch={(e) => {
          if (cType === "video") {
            if (e === "") {
              setSuggestoins();
            } else {
              getQueries(e);
            }
          }
          setTextField(e);
        }}
        placeholder="Start Your Idea Here"
        className="input-right-radius"
      />

      <div className="duration-box-m">
        <Select
          placeholder="Time"
          style={{ width: 100 }} // Set the desired width in pixels
          value={duration}
          onChange={(value) => {
            if (
              organization &&
              organization.subscription === "Pro" &&
              value !== 1
            ) {
              setShowPrice(true);
            } else {
              setDuration(value);
            }
          }}
          className="select-left-radius"
        >
          {/* <Option disabeled value={""}>
        Duration
      </Option> */}

          <Option value={1}>
            {" "}
            <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
            3s
          </Option>
          <Option value={2}>
            {" "}
            <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
            6s
          </Option>
          <Option value={4}>
            <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
            12s
          </Option>
          <Option value={6}>
            {" "}
            <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
            18s
          </Option>
          <Option value={8}>
            {" "}
            <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
            24s
          </Option>
          <Option value={10}>
            {" "}
            <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
            30s
          </Option>
          <Option value={12}>
            {" "}
            <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
            36s
          </Option>
          <Option value={14}>
            {" "}
            <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
            42s
          </Option>
          <Option value={16}>
            {" "}
            <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
            48s
          </Option>
          <Option value={18}>
            {" "}
            <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
            54s
          </Option>
          <Option value={20}>
            {" "}
            <BiTime style={{ marginRight: "5px", fontSize: "20px" }} />
            1m
          </Option>
        </Select>
      </div>
      {textField && textField.length ? (
        <div className="create-button-con">
          {loading ? <Spin /> : null}
          {cType === "video" ? (
            <button
              onClick={() => {
                handleSendClick();
                setloading(true);
                setGeneratedID(textField);
              }}
              className="button-21"
            >
              Imagine
              <div className="hoverEffect">
                <div></div>
              </div>
            </button>
          ) : cType === "image" ? (
            <button
              onClick={() => {
                handleSendClick2();
                setloading(true);
                setGeneratedID(textField);
              }}
              className="button-21"
            >
              Imagine
              <div className="hoverEffect">
                <div></div>
              </div>
            </button>
          ) : cType === "sound" ? (
            <button
              onClick={() => {
                handleSendClick3();
                setloading(true);
                setGeneratedID(textField);
              }}
              className="button-21"
            >
              Imagine
              <div className="hoverEffect">
                <div></div>
              </div>
            </button>
          ) : cType === "music" ? (
            <button
              onClick={() => {
                handleSendClick5();
                setloading(true);
                setGeneratedID(textField);
              }}
              className="button-21"
            >
              Imagine
              <div className="hoverEffect">
                <div></div>
              </div>
            </button>
          ) : (
            <button
              onClick={() => {
                handleSendClick4();
                setloading(true);
                setGeneratedID(textField);
              }}
              className="button-21"
            >
              Imagine
              <div className="hoverEffect">
                <div></div>
              </div>
            </button>
          )}
        </div>
      ) : null}
    </div>
  ) : (
    <div className="icons-top-story">
      <Tooltip title="Create Music">
        <div onClick={() => setCtype("music")}>
          <FaMusic style={{ fontSize: "16px" }} />
        </div>
      </Tooltip>
      <Tooltip title="Create voice">
        <div onClick={() => setCtype("voice")}>
          <RiChatVoiceFill />
        </div>
      </Tooltip>
      <Tooltip title="Create video">
        <div onClick={() => setCtype("video")}>
          <BsFillCameraVideoFill />{" "}
        </div>
      </Tooltip>
      <Tooltip title="Create image">
        <div onClick={() => setCtype("image")}>
          <BiSolidImage />
        </div>
      </Tooltip>
      <Tooltip title="Create sound">
        <div onClick={() => setCtype("sound")}>
          <BsSoundwave />
        </div>
      </Tooltip>
    </div>
  );
}
