import React, { useContext, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";
import { Badge, Button, Input, Skeleton, Tag, message } from "antd";
import { FaExpandAlt, FaPlay } from "react-icons/fa";
import ReactAudioPlayer from "react-audio-player";

import temp from "../../../../images/56f4b8301c915c89bdfb278a9b9f6bbb.png";
import { GoDotFill } from "react-icons/go";
import { MdClose } from "react-icons/md";
import AddBlock from "./AddBlock";

export default function Blocks() {
  const { editor, seteditor, user } = useContext(SearchContext);
  const [p, setP] = useState();
  const UpdateBlock = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/block/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          const all = editor.blocks.map((b) =>
            b._id === id ? response.blockData : b
          );
          seteditor({ ...editor, blocks: all });
        } else {
          // message.error("somehing went wrong");
        }
      });
  };
  const changeValue = (row, key, value) => {
    row[key] = value;
    const all = editor.blocks.map((b) => (b._id === row._id ? row : b));
    seteditor({ ...editor, blocks: all });
  };
  const deleteTask = (row, key) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/block/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
          const all = editor.blocks.filter((p) => p._id !== row._id);
          seteditor({ ...editor, blocks: all });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };
  return (
    <div
      style={{ paddingRight: "10px" }}
      className="videos-left-ed"
      onClick={() => console.log(editor)}
    >
      {editor.blocks &&
        editor.blocks.map((g, gi) => {
          return (
            <Badge.Ribbon
              color="#113ccf"
              text={
                g.block_type === "writing_style"
                  ? "Writing Style"
                  : g.block_type
              }
            >
              <div
                key={gi}
                style={{
                  width: "100%",
                  marginRight: "0px",

                  height: "auto",
                }}
                className="video-card-story"
              >
                {g.block_type === "product" || g.block_type === "character" ? (
                  <div className="sound-thumbnail-img">
                    <img className="" src={g.file_url} alt="" />

                    <div className="sound-thmb-qa">
                      <div className="delete-icon-c">
                        <b onClick={() => deleteTask(g)}>
                          <MdClose className="hover" />
                        </b>
                      </div>
                      <div className="name-tg-b">
                        <input
                          style={{
                            width: "100%",
                          }}
                          type="text"
                          placeholder="Block Name"
                          onChange={(e) =>
                            changeValue(g, "block_name", e.target.value.trim())
                          }
                          onBlur={() =>
                            UpdateBlock(
                              {
                                block_name: g.block_name,
                              },
                              g._id
                            )
                          }
                          value={g.block_name}
                          required=""
                          className="form-control border-0 shadow-sm px-4 "
                        />
                      </div>
                      <div className="bpttpm-icn-wc">
                        <GoDotFill
                          onClick={() => {
                            UpdateBlock(
                              {
                                status:
                                  g.status === "active" ? "pending" : "active",
                              },
                              g._id
                            );
                            changeValue(
                              g,
                              "status",
                              g.status === "active" ? "pending" : "active"
                            );
                          }}
                          style={{
                            fontSize: "20px",
                            color: g.status === "active" ? "green" : "red",
                          }}
                          className="hover"
                        />
                      </div>
                    </div>
                  </div>
                ) : g.block_type === "writing_style" ? (
                  <div className="sound-thumbnail-img">
                    <div className="sound-thmb-qa writing-style-input">
                      <div className="delete-icon-c">
                        <b onClick={() => deleteTask(g)}>
                          <MdClose className="hover" />
                        </b>
                      </div>
                      <div className="name-tg-b ">
                        <input
                          style={{
                            width: "100%",
                          }}
                          type="text"
                          placeholder="Block Name"
                          onChange={(e) =>
                            changeValue(g, "block_name", e.target.value.trim())
                          }
                          onBlur={() =>
                            UpdateBlock(
                              {
                                block_name: g.block_name,
                              },
                              g._id
                            )
                          }
                          value={g.block_name}
                          required=""
                          className="form-control border-0 shadow-sm px-4 "
                        />
                        <Input.TextArea
                          style={{ marginTop: "10px" }}
                          placeholder="Writing Text"
                          onChange={(e) =>
                            changeValue(g, "block_text", e.target.value)
                          }
                          onBlur={() =>
                            UpdateBlock(
                              {
                                block_text: g.block_text,
                              },
                              g._id
                            )
                          }
                          value={g.block_text}
                          autoSize={{ minRows: 1, maxRows: 3 }}
                        />
                      </div>
                      <div className="bpttpm-icn-wc">
                        <GoDotFill
                          onClick={() => {
                            UpdateBlock(
                              {
                                status:
                                  g.status === "active" ? "pending" : "active",
                              },
                              g._id
                            );
                            changeValue(
                              g,
                              "status",
                              g.status === "active" ? "pending" : "active"
                            );
                          }}
                          style={{
                            fontSize: "20px",
                            color: g.status === "active" ? "green" : "red",
                          }}
                          className="hover"
                        />
                      </div>
                    </div>
                  </div>
                ) : g.block_type === "voice" ? (
                  <div className="sound-thumbnail-img">
                    {p === g._id ? null : (
                      <div className="play-icon-coive0">
                        <FaPlay onClick={() => setP(g._id)} />
                      </div>
                    )}
                    <div className="sound-thmb-qa">
                      <div className="delete-icon-c">
                        <b onClick={() => deleteTask(g._id)}>
                          <MdClose className="hover" />
                        </b>
                      </div>
                      <div className="name-tg-b">
                        <input
                          style={{
                            width: "100%",
                          }}
                          type="text"
                          placeholder="Block Name"
                          onChange={(e) =>
                            changeValue(g, "block_name", e.target.value.trim())
                          }
                          onBlur={() =>
                            UpdateBlock(
                              {
                                block_name: g.block_name,
                              },
                              g._id
                            )
                          }
                          value={g.block_name}
                          required=""
                          className="form-control border-0 shadow-sm px-4 "
                        />
                      </div>
                      {p === g._id ? (
                        <ReactAudioPlayer style={{marginTop:"10px"}} src={g.file_url} autoPlay controls />
                      ) : (
                        null
                      )}
                      <div className="bpttpm-icn-wc">
                        <GoDotFill
                          onClick={() => {
                            UpdateBlock(
                              {
                                status:
                                  g.status === "active" ? "pending" : "active",
                              },
                              g._id
                            );
                            changeValue(
                              g,
                              "status",
                              g.status === "active" ? "pending" : "active"
                            );
                          }}
                          style={{
                            fontSize: "20px",
                            color: g.status === "active" ? "green" : "red",
                          }}
                          className="hover"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </Badge.Ribbon>
          );
        })}
    </div>
  );
}
