import React, { useContext, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";
import { Button, Skeleton } from "antd";
import { FaExpandAlt, FaPlay } from "react-icons/fa";
import ReactAudioPlayer from "react-audio-player";
import { MdMusicVideo } from "react-icons/md";
import temp from "../../../../images/56f4b8301c915c89bdfb278a9b9f6bbb.png";
import { BiSolidUserVoice } from "react-icons/bi";
import Paragraph from "antd/lib/typography/Paragraph";

export default function Voices() {
  const { editor, seteditor, user } = useContext(SearchContext);
  const [p, setP] = useState();

  return (
    <div className="videos-left-ed" onClick={() => console.log(editor)}>
      {editor.voices &&
        editor.voices.map((g, gi) => {
          return (
            <div
              key={gi}
              style={{
                width: p && p._id === g._id ? "100%" : "140px",
                marginRight: p && p._id === g._id ? "0px" : "10px",

                height: "auto",
              }}
              className="video-card-story"
            >
              {g.quene === "progress" ? (
                <div style={{ background: "grey", borderRadius: "5px" }}>
                  <Skeleton.Button active size={80} shape={"default"} />
                </div>
              ) : p && p._id === g._id ? (
                <div className="video-container video-container-round">
                  <Paragraph
                    ellipsis={{
                      rows: 3,
                      // expandable: true,
                      symbol: "see more",
                    }}
                    className="desc-p-crdjjkl"
                  >
                    {g.query}
                  </Paragraph>

                  <ReactAudioPlayer src={g.file_url} autoPlay controls />
                </div>
              ) : (
                <div className="music-thmb-qa">
                  <div className="bpttpm-icn-wc">
                    <BiSolidUserVoice onClick={() => setP(g)} />
                  </div>
                  <Paragraph
                    ellipsis={{
                      rows: 2,
                      // expandable: true,
                      symbol: "see more",
                    }}
                    className="desc-p-crdjjkl"
                  >
                    {g.query}
                  </Paragraph>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}
