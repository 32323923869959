import { Button, Input, Modal, Tooltip, message } from "antd";
import React, { useContext, useState } from "react";
import { BiBookBookmark, BiLibrary } from "react-icons/bi";
import { BsFillChatDotsFill } from "react-icons/bs";
import { FaDownload, FaMagic, FaPaintBrush } from "react-icons/fa";
import SearchContext from "../../../../Context/SearchContext";
import { MdOutlineShare } from "react-icons/md";
import { IoMdInformationCircle } from "react-icons/io";
import JSZip from "jszip";

export default function RightIcons() {
  const {
    editor,
    seteditor,
    user,
    stories,
    setStories,
    storyChange,
    setStoryChange,
    editorLeft,
    seteditorLeft,
  } = useContext(SearchContext);
  const [showDownload, setShowDownload] = useState(false);
  const [showShare, setShowShare] = useState();
  const [showShare2, setShowShare2] = useState();

  const downloadImages = async () => {
    const images = [...stories.data];
    const zip = new JSZip();
    const promises = [];

    // Create an array of promises for each fetch operation
    images.forEach((image) => {
      const filename = `image_${image._id}.jpg`;
      const promise = fetch(image.file_url)
        .then((response) => response.blob())
        .then((blob) => zip.file(filename, blob));
      promises.push(promise);
    });

    // Wait for all promises to resolve before generating the ZIP file
    Promise.all(promises).then(() => {
      zip.generateAsync({ type: "blob" }).then((content) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(content);
        link.download = "images.zip";
        link.click();
      });
    });
  };

  const copyToClipboard2 = () => {
    const textToCopy = `${window.location.origin}/share/${showShare}`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("Link copied");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        message.error("Failed to copy link");
      });
  };
  const copyToClipboard = () => {
    const textToCopy = showShare2.share;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("Link copied");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
        message.error("Failed to copy link");
      });
  };

  return (
    <div className="right-icon-edit-c">
      <Tooltip placement="left" title="Generate">
        <div
          className={` ${editor.imageBlank === "generate" ? " actt" : ""}`}
          onClick={() =>
            seteditor({
              ...editor,
              imageBlank: "generate",
            })
          }
        >
          <FaMagic />
        </div>
      </Tooltip>
      <Tooltip placement="left" title="Paint">
        <div
          className={` ${editor.imageBlank === "paint" ? " actt" : ""}`}
          onClick={() =>
            seteditor({
              ...editor,
              imageBlank: "paint",
            })
          }
        >
          <FaPaintBrush />
        </div>
      </Tooltip>
      <Tooltip placement="left" title="Library">
        <div>
          <BiLibrary />
        </div>
      </Tooltip>
      <Tooltip placement="left" title="CoPilot">
        <div>
          <BsFillChatDotsFill />
        </div>
      </Tooltip>
      <Tooltip placement="left" title="Guide">
        <div>
          <BiBookBookmark />
        </div>
      </Tooltip>
      <Tooltip placement="left" title="Download">
        <div onClick={() => setShowDownload(true)}>
          <FaDownload />
        </div>
      </Tooltip>
      {editor.activeContent ? (
        <Tooltip placement="left" title="Share">
          <div
            onClick={() =>
              setShowShare2({
                ...editor.activeContent,
                share: `${window.location.origin}/share/${editor.activeContent._id}`,
              })
            }
          >
            <MdOutlineShare />
          </div>
        </Tooltip>
      ) : null}
      {stories.active ? (
        <Tooltip placement="left" title="Share Project">
          <div onClick={() => setShowShare(stories.active._id)}>
            <MdOutlineShare />
          </div>
        </Tooltip>
      ) : null}
      <Tooltip placement="left" title="Info">
        <div>
          <IoMdInformationCircle />
        </div>
      </Tooltip>
      <Modal
        open={showDownload}
        onCancel={() => {
          setShowDownload(false);
        }}
        width={464}
        // onOk={() => setIsGet(isGet + 1)}
        className="no-buttons-modal black-modal"
      >
        <button onClick={downloadImages}>Download Images</button>
      </Modal>
      <Modal
        open={showShare !== undefined}
        onCancel={() => {
          setShowShare();
        }}
        width={464}
        // onOk={() => setIsGet(isGet + 1)}
        className="no-buttons-modal black-modal"
      >
        <Input
          onChange={(e) => {
            setShowShare(e.target.value);
          }}
          value={showShare}
        />
        <div style={{ marginTop: "10px" }} className="flex-end">
          <Button onClick={copyToClipboard}>copy</Button>
        </div>
      </Modal>
      <Modal
        open={showShare2 !== undefined}
        onCancel={() => {
          setShowShare2();
        }}
        width={464}
        // onOk={() => setIsGet(isGet + 1)}
        className="no-buttons-modal black-modal"
      >
        {showShare2 ? (
          <div>
            <Input
              onChange={(e) => {
                setShowShare2({ ...showShare2, share: e.target.value });
              }}
              value={showShare2.share}
            />
            <div style={{ marginTop: "10px" }} className="flex-end">
              <Button onClick={copyToClipboard}>copy</Button>
            </div>
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
