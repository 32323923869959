import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SearchContext from "../Context/SearchContext";
import { Dropdown } from "antd";
import logo from "../images/logo-s.png";
import CreateImagine from "./CreateImagine";
import Imagine from "../pages/storyProject/components/Imagine";
import { TbProgressBolt } from "react-icons/tb";
export default function Header({
  showSearch,
  setDisplayCreate,
  generated,
  generatedVID,
  setGeneratedVID,
  search,
  setSearch,
  displayCreate,
  getVideos22,
  showP,
}) {
  const navigate = useNavigate();
  const {
    user,
    setUser,
    setOrganization,
    resetAll,
    videosFeed,
    setVideosFeed,
  } = useContext(SearchContext);
  const location = useLocation();
  const [path, setPath] = useState("/");

  useEffect(() => {
    if (location) {
      setPath(location.pathname);
    }
  }, [location]);

  const items = [
    {
      key: "2",
      label: <span onClick={() => logout()}>Log Out</span>,
    },
  ];

  const logout = () => {
    window.localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
    setUser();
    setOrganization();
    resetAll();
  };

  const getVideos = (v) => {
    const obj = { query: v };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/image/watch/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setVideosFeed([...[], ...data]);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      });
  };

  const changeSearch = (v) => {
    setSearch(v);
    if (v.trim().length) {
      getVideos(v.trim());
    } else {
      getVideos2();
    }
  };

  const getVideos2 = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(
      `https://embed.axv.ai/api/image/watch?user_id=${user && user.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setVideosFeed([...[], ...data.imageList]);
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
      });
  };
  const isWatchPage = path === "/watch";
  const isStudioPage = path === "/studio";
  const isHomePage = path === "/";
  const isLoggedIn = user && user.name;

  return (
    <div className="header-container">
      <nav className="navbar navbar-expand-lg navbar-light ">
        <div className="container-fluid my-container-flui">
          {/* Make the logo a link to the home page */}
          <Link to="/">
            <a className="navbar-brand" href="#">
              <img className="logo-img-bc" src={logo} alt="" />
            </a>
          </Link>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
          <div className=" navbar-my-main">
            <span />
            {showP ? (
              <div className=" card-imagine-create2">
                {displayCreate ? null : (
                  <div
                    onClick={() => setDisplayCreate(true)}
                    className="create-display-icon"
                  >
                    <TbProgressBolt />
                  </div>
                )}
                <Imagine
                  displayCreate={displayCreate}
                  setDisplayCreate={setDisplayCreate}
                  getVideos={getVideos22}
                  generated={generated}
                  generatedVID={generatedVID}
                  setGeneratedVID={setGeneratedVID}
                />
              </div>
            ) : null}

            {showSearch ? (
              <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                <div class="container-s">
                  <input
                    type="text"
                    name="text"
                    class="input-s"
                    required=""
                    placeholder="Imagine Anything"
                    value={search}
                    onChange={(e) => {
                      changeSearch(e.target.value);
                    }}
                  />
                  <div class="icon-s">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="ionicon-s"
                      viewBox="0 0 512 512"
                    >
                      <title>Search</title>
                      <path
                        d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                        fill="none"
                        stroke="currentColor"
                        stroke-miterlimit="10"
                        stroke-width="32"
                      ></path>
                      <path
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-miterlimit="10"
                        stroke-width="32"
                        d="M338.29 338.29L448 448"
                      ></path>
                    </svg>
                  </div>
                </div>
              </ul>
            ) : null}
            <div className="header-right-c">
              {displayCreate === undefined &&
              window.location.pathname !== "/" &&
              window.location.pathname !== "/login" &&
              window.location.pathname !== "/watch/try" &&
              window.location.pathname !== "/signup" ? (
                <CreateImagine header={true} />
              ) : null}
              <div className="ml-auto">
                {isWatchPage ? (
                  <Link to="/studio">
                    <button className="custom-button">Studio</button>
                  </Link>
                ) : isStudioPage ? (
                  <Link to="/watch">
                    <button className="custom-button">Watch</button>
                  </Link>
                ) : isHomePage ? (
                  isLoggedIn ? ( // Change the button label to "Studio" if logged in
                    <Link to="/studio">
                      <button className="custom-button">Studio</button>
                    </Link>
                  ) : (
                    <Link to="/login">
                      <button className="custom-button">
                        Get Started For Free
                      </button>
                    </Link>
                  )
                ) : null}
              </div>

              {isLoggedIn ? (
                <div className="roadm-t-div" style={{ marginLeft: "20px" }}>
                  <Dropdown
                    menu={{
                      items,
                    }}
                    placement="bottomLeft"
                  >
                    <span className="btn-appr signop-btn">{user.name}</span>
                  </Dropdown>
                </div>
              ) : (
                <form className="d-flex"></form>
              )}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
