import React, { useContext } from "react";
import SearchContext from "../../../../Context/SearchContext";
import GreenTick from "../../../../components/GreenTick";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { Popover, Spin } from "antd";
import fx from "../../../../images/fx2.png";
import { LoadingOutlined } from "@ant-design/icons";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
export default function Goals() {
  const { editor, seteditor, stories, setStories } = useContext(SearchContext);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const changeData = (d) => {
    let all = stories.data.map((f) => (f._id === d._id ? d : f));
    setStories({
      ...stories,
      data: all,
    });
  };
  return (
    <div>
      {" "}
      <div className="cards-buttons-smal planing-goals">
        <div style={{ padding: "0px" }} className={` start-card-bx `}>
          <div className="icon-planing-c">
            <img src={fx} alt="" />
          </div>
        </div>
        <div className={` start-card-bx `}>
          <div className="icon-planing-c">
            {editor.activeContent.imgGoals.status === "complete" ? (
              <GreenTick width={"20px"} height={"20px"} />
            ) : (
              <Spin indicator={antIcon} />
            )}

            <h5>
              {editor.activeContent.imgGoals.status}
              <Popover title="Planning">
                <BsFillInfoCircleFill
                  style={{ marginLeft: "10px" }}
                  className="hover"
                />
              </Popover>
            </h5>
          </div>
        </div>
        {editor.activeContent.imgGoals.goals &&
          editor.activeContent.imgGoals.goals.map((m, mi) => {
            return (
              <div
                key={mi}
                className={` start-card-bx 
                ${
                  m.progress_bar <= 20
                    ? "red-bg-gr"
                    : m.progress_bar === 100
                    ? "green-bg-gr"
                    : "yellow-bg-gr"
                }
                `}
              >
                <div className="icon-planing-c">
                  {/* {m.quene === "complete" ? (
                    <GreenTick width={"20px"} height={"20px"} />
                  ) : m.quene === "progress" ? (
                    <Spin indicator={antIcon} />
                  ) : (
                    <AiOutlineCloseCircle className="red-tick-t" />
                  )} */}

                  <h5>
                    {m.job_type.replaceAll("_", " ")}
                    {m.progress_bar === 100 &&
                    (m.job_type === "remove_background" ||
                      m.job_type === "text_image") ? (
                      <span>
                        {(editor.activeContent.file_url2 === m.file_url &&
                          m.job_type === "remove_background") ||
                        (editor.activeContent.file_text === m.generated_text &&
                          m.job_type === "text_image") ? (
                          <AiFillEye
                            style={{ marginLeft: "10px" }}
                            className="hover"
                            onClick={() => {
                              seteditor({
                                ...editor,
                                activeContent: {
                                  ...editor.activeContent,
                                  file_url2: undefined,
                                  file_text: undefined,
                                },
                              });
                              changeData({
                                ...editor.activeContent,
                                file_url2: undefined,
                                file_text: undefined,
                              });
                            }}
                          />
                        ) : (
                          <AiFillEyeInvisible
                            style={{ marginLeft: "10px" }}
                            className="hover"
                            onClick={() => {
                              seteditor({
                                ...editor,
                                activeContent: {
                                  ...editor.activeContent,
                                  file_url2:
                                    m.job_type === "text_image"
                                      ? undefined
                                      : m.file_url,
                                  file_text:
                                    m.job_type === "text_image"
                                      ? m.generated_text
                                      : undefined,
                                },
                              });
                              changeData({
                                ...editor,
                                activeContent: {
                                  ...editor.activeContent,
                                  file_url2:
                                    m.job_type === "text_image"
                                      ? undefined
                                      : m.file_url,
                                  file_text:
                                    m.job_type === "text_image"
                                      ? m.generated_text
                                      : undefined,
                                },
                              });
                            }}
                          />
                        )}
                      </span>
                    ) : (
                      <Popover title="Background removal involves focusing on the main object in the image and making everything else white.">
                        <BsFillInfoCircleFill
                          style={{ marginLeft: "10px" }}
                          className="hover"
                        />
                      </Popover>
                    )}
                  </h5>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
