import React, { useContext, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";
import { Button, Input } from "antd";

export default function DevelopersHub() {
  const { user, setUser, organization, setShowPrice } =
    useContext(SearchContext);
  const [visible, setVisible] = useState();

  return (
    <div>
      <div className="profile-edit-page left-dev-form">
        {organization ? (
          <div>
            <div className="form-group mb-3">
              <b>Api Key:</b>
              <Input.Password
                placeholder="input password"
                value={organization.api_key}
                visibilityToggle={{
                  visible: visible,
                  onVisibleChange: setVisible,
                }}
                style={{ marginTop: "5px" }}
              />
            </div>
            <div className="form-group mb-3">
              <b>Usage:</b> <span>${organization.chat_count * 0.03} </span>
            </div>
            <div className="form-group mb-3">
              {" "}
              <b> Balance:</b>{" "}
              <span>${organization.reserved_compute_credits} </span>
            </div>
            <div className="space-between">
              <a
                href="https://buy.stripe.com/dR68xFbqyfkH4TKdR4"
                target="blank"
              >
                <Button className={`editor-tag-tab active-tg-wdt`} type="primary">Add Credit</Button>
              </a>
              <a href="https://docs.olm.ai/" target="blank">
                <Button className={`editor-tag-tab active-tg-wdt`}>Documentation</Button>
              </a>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
