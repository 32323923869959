import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";
import {
  Alert,
  Button,
  Dropdown,
  Progress,
  Select,
  Skeleton,
  Tooltip,
} from "antd";
import { CgNotes } from "react-icons/cg";
import { FaHistory, FaMagic, FaPlus } from "react-icons/fa";
import { RiChatVoiceFill } from "react-icons/ri";
import { BiBookBookmark, BiLibrary, BiSolidHomeSmile } from "react-icons/bi";
import { BsFillChatDotsFill } from "react-icons/bs";
import Generate from "./Generate";
import axios from "axios";
import { MdClose, MdSmsFailed } from "react-icons/md";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
const { Option } = Select;
export default function VideoBlank() {
  const navigate = useNavigate();

  const { editor, seteditor, user } = useContext(SearchContext);
  const [callImg, setCallImg] = useState(1);
  const [imgId, setImgId] = useState();
  // const [createdImg, setCreatedImg] = useState();
  const [failed, setFailed] = useState();

  const callGet = (d) => {
    setImgId(d);
    setCallImg(callImg + 1);
    // seteditor({ ...editor, iconsRight: true });
  };
  useEffect(() => {
    if (imgId) {
      setFailed();
      getImgGenerated();
    }
  }, [callImg]);

  const getImgGenerated = async (isThunder) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(
        `https://embed.axv.ai/api/image/${imgId && imgId._id}`
      );

      // Update the state with the retrieved jobs data

      if (response.data?.imageData?.quene === "progress") {
        setTimeout(() => {
          setCallImg(callImg + 1);
          setImgId({
            ...imgId,
            progress: response.data?.imageData.progress_bar,
          });
        }, 8000);
      } else if (response.data?.imageData?.quene === "complete") {
        setImgId();
        seteditor({
          ...editor,
          activeContent: response.data?.imageData,
        });
      } else {
        setImgId();
        setFailed(response.data?.imageData.query);
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };
  const items = [
    {
      key: "2",
      label: <span onClick={() => logout()}>Log Out</span>,
    },
  ];
  const logout = () => {
    window.localStorage.removeItem("user");
    navigate("/");
    window.location.reload();
  };
  return (
    <div className="content-ed-main">
      <div className="content-img-bx">
        <div className="coonetnt-img-bx-m1">
          <div className="duration-box-m start-inputo">
            <span>
              <BiSolidHomeSmile
                onClick={() => {
                  seteditor({
                    cType: "video",
                    width: 80,
                    vPageNo: 1,
                    iPageNo: 1,
                    sPageNo: 1,
                    voPageNo: 1,
                    bPageNo: 1,
                  });
                }}
                className="home-icon0-t"
              />
              <Select
                placeholder="Time"
                style={{ width: 100 }} // Set the desired width in pixels
                value={editor.activeType}
                onChange={(value) => {
                  seteditor({
                    ...editor,
                    activeContent: {},
                    activeType: value,
                  });
                }}
                className="select-left-radius"
              >
                <Option value={"image"}>Image</Option>
                <Option value={"video"}>Video</Option>
                <Option value={"audio"}>Audio</Option>
              </Select>
            </span>
            {user && user.name ? (
              <div className="roadm-t-div" style={{ marginLeft: "20px" }}>
                <Dropdown
                  menu={{
                    items,
                  }}
                  placement="bottomLeft"
                >
                  <span
                    style={{ color: "white" }}
                    className="btn-appr signop-btn"
                  >
                    {user.name}
                  </span>
                </Dropdown>
              </div>
            ) : (
              <form className="d-flex"></form>
            )}
          </div>
          <div className="center-input-u-img">
            <input
              style={{
                width: 300,
              }}
              type="text"
              placeholder="Search"
              //   onChange={(e) => setSearch(e.target.value)}
              //   value={search}
              required=""
              className="form-control border-0 shadow-sm px-4 "
            />
          </div>
          {editor.activeContent && editor.activeContent.file_url ? (
            <div className="blank-page-video-c">
              <div className="close-icon">
                <MdClose
                  onClick={() =>
                    seteditor({
                      ...editor,
                      activeContent: {},
                    })
                  }
                />
              </div>
              <div className="video-container video-container-round">
                <ReactPlayer
                  playing
                  url={editor.activeContent.file_url}
                  controls
                />
              </div>
              <div className="close-icon plus-icon">
                <FaPlus onClick={() => {}} />
              </div>
            </div>
          ) : imgId ? (
            <div className="img-loader-bx">
              <Skeleton.Image active />
              <Progress
                status="active"
                percent={imgId.progress ? imgId.progress : 0}
              />
            </div>
          ) : (
            <div className="blank-area-box video-gene">
              {editor.imageBlank === "generate" ? (
                <div>
                  {failed ? (
                    <Alert
                      message="Generation Failed"
                      description="Your generation failed. We’re sorry about that. Please try again"
                      type="info"
                      showIcon
                      className="info-elrt-m"
                      icon={<MdSmsFailed />}
                    />
                  ) : null}
                  <Generate callGet={callGet} />
                </div>
              ) : null}
            </div>
          )}
        </div>
        {editor.activeContent && editor.activeContent.file_url ? (
          <div className="right-icon-edit-c">
            <Tooltip placement="left" title="Generate">
              <div
                className={` ${
                  editor.imageBlank === "generate" ? " actt" : ""
                }`}
              >
                <FaMagic />
              </div>
            </Tooltip>
            <Tooltip placement="left" title="Library">
              <div>
                <BiLibrary />
              </div>
            </Tooltip>
            <Tooltip placement="left" title="CoPilot">
              <div>
                <BsFillChatDotsFill />
              </div>
            </Tooltip>
            <Tooltip placement="left" title="Guide">
              <div>
                <BiBookBookmark />
              </div>
            </Tooltip>
          </div>
        ) : (
          <div className="cards-buttons-smal">
            <div
              onClick={() =>
                seteditor({
                  ...editor,
                  imageBlank: "generate",
                })
              }
              className={` start-card-bx ${
                editor.imageBlank === "generate" ? " active-icn-bb" : ""
              }`}
            >
              <div>
                <FaMagic />
                <h5>Generate</h5>
              </div>
              <p>Use AI to generate your idea from scratch.</p>
            </div>
            <div className="start-card-bx">
              <div>
                <BiLibrary />
                <h5>Library</h5>
              </div>
              <p>Use an existing asset from your library.</p>
            </div>
            <div className="start-card-bx">
              <div>
                <BsFillChatDotsFill />
                <h5>CoPilot</h5>
              </div>
              <p>Use AI to generate ideas about what you should create.</p>
            </div>
            <div className="start-card-bx">
              <div>
                <BiBookBookmark />
                <h5>Guide</h5>
              </div>
              <p>Learn more about what you can do with FX Engine</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
