import React, { useContext, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";
import { Button, Skeleton } from "antd";
import { FaExpandAlt, FaPlay } from "react-icons/fa";
import ReactAudioPlayer from "react-audio-player";
import { MdMusicVideo } from "react-icons/md";
import temp from "../../../../images/56f4b8301c915c89bdfb278a9b9f6bbb.png";

export default function Music() {
  const { editor, seteditor, user } = useContext(SearchContext);
  const [p, setP] = useState();

  return (
    <div className="videos-left-ed" onClick={() => console.log(editor)}>
      {editor.musics &&
        editor.musics.map((g, gi) => {
          return (
            <div
              key={gi}
              style={{
                width: p && p._id === g._id ? "100%" : "140px",
                marginRight: p && p._id === g._id ? "0px" : "10px",
                // backgroundColor:
                //   g.quene === "progress" ? "grey" : "transparent",

                height: "auto",
              }}
              className="video-card-story"
            >
              {g.quene === "progress" ? (
                <div style={{ background: "grey", borderRadius: "5px" }}>
                  <Skeleton.Button active size={115} shape={"default"} />
                </div>
              ) : p && p._id === g._id ? (
                <div className="video-container video-container-round">
                  <p>{g.query}</p>

                  <ReactAudioPlayer src={g.file_url} autoPlay controls />
                </div>
              ) : (
                <div className="music-thmb-qa">
                 
                    <div className="bpttpm-icn-wc">
                      <MdMusicVideo onClick={() => setP(g)} />
                    </div>
              
                  <p>{g.query}</p>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}
