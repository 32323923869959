import React from "react";
import { FaPlay } from "react-icons/fa";

export default function FullLoader() {
  return (
    <div className="loading-full-page trasparent-loader">
      <div className="spiner-main-box">
        <div className="spinner"></div> <FaPlay />
      </div>
    </div>
  );
}
