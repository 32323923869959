import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../../images/CFX (1).png";
import { Tabs, Tag, Tooltip } from "antd";
import { FaMagic, FaMusic, FaUsb, FaUser } from "react-icons/fa";
import { RiChatVoiceFill } from "react-icons/ri";
import {
  BsBox,
  BsChevronCompactLeft,
  BsChevronCompactRight,
  BsCode,
  BsFillCameraVideoFill,
  BsSoundwave,
  BsClipboard2Fill,
} from "react-icons/bs";
import { BiSolidImage } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import { FiUpload } from "react-icons/fi";

import SearchContext from "../../../../Context/SearchContext";
import Search from "./Search";
import Videos from "./Videos";
import Images from "./Images";
import Sounds from "./Sounds";
import Music from "./Music";
import Voices from "./Voices";
import Imagine from "./Imagine";
import UploadVideo from "./UploadVideo";
import axios from "axios";
import UploadImage from "./UploadImage";
import UploadAudio from "./UploadAudio";
import Blocks from "./Blocks";
import AddBlock from "./AddBlock";
import Free from "./Free";
import Projects from "./Projects";
import DevelopersHub from "./DevelopersHub";
import Profile from "./Profile";
export default function Left({ w, setW }) {
  const location = useLocation();
  const { editor, seteditor, user, editorLeft } = useContext(SearchContext);
  const [callApi, setCallApi] = useState(1);
  const [dAdd, setdAdd] = useState();

  const [tab, setTab] = useState("1");
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (search.length) {
      if (editor.cType === "free") {
        getFreeData();
      } else {
        getVideos();
      }
    } else {
      if (editor.cType === "video" && editor.vPageNo === 1) {
        getVideoGenerated();
      } else if (editor.cType === "image" && editor.iPageNo === 1) {
        getImagesGenerated();
      } else if (editor.cType === "music" && editor.mPageNo === 1) {
        getMusicGenerated();
      } else if (editor.cType === "sound" && editor.sPageNo === 1) {
        getSoundGenerated();
      } else if (editor.cType === "voice" && editor.voPageNo === 1) {
        getVoiceGenerated();
      } else if (editor.cType === "block" && editor.bPageNo === 1) {
        getBlocksGenerated();
      } else if (editor.cType === "canvas" && editor.cnPageNo === 1) {
        getProjects();
      }
    }
  }, [search, callApi, editorLeft]);

  const getFreeData = () => {
    const obj = {
      query: search,
      media_type: "sound",
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/minute/library/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          seteditor({ ...editor, free: data });
        }
      });
  };

  const getVideos = () => {
    const obj = {
      query: search,
      user_id: user && user.user_id,
      media_type: editor.cType === "video" ? "original" : editor.cType,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };

    fetch(`https://embed.axv.ai/api/image/watch/search/user`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          if (editor.cType === "video") {
            seteditor({ ...editor, videos: data });
          } else if (editor.cType === "image") {
            seteditor({ ...editor, images: data });
          } else if (editor.cType === "music") {
            seteditor({ ...editor, musics: data });
          } else if (editor.cType === "sound") {
            seteditor({ ...editor, sounds: data });
          } else if (editor.cType === "voice") {
            seteditor({ ...editor, voices: data });
          }
        }
      });
  };
  const getVideoGenerated = async (isThunder) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(
        `https://embed.axv.ai/api/image/?page=1&limit=20&sort_by=${-1}&user_id=${
          user && user.user_id
        }&media_type=original&status=generated`
      );

      // Update the state with the retrieved jobs data
      seteditor({
        ...editor,
        videos: response.data?.imageList,
        totalPages: response.data?.totalPages,
      });

      setTimeout(() => {
        setCallApi(callApi + 1);
      }, 10000);
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };
  const getImagesGenerated = async (isThunder) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(
        `https://embed.axv.ai/api/image/?page=1&limit=20&sort_by=${-1}&user_id=${
          user && user.user_id
        }&media_type=image&status=generated`
      );

      // Update the state with the retrieved jobs data
      seteditor({
        ...editor,
        images: response.data?.imageList,
        totalPages: response.data?.totalPages,
      });

      setTimeout(() => {
        setCallApi(callApi + 1);
      }, 10000);
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };
  const getVoiceGenerated = async (isThunder) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(
        `https://embed.axv.ai/api/image/?page=1&limit=20&sort_by=${-1}&user_id=${
          user && user.user_id
        }&media_type=voice&status=generated`
      );

      // Update the state with the retrieved jobs data
      seteditor({
        ...editor,
        voices: response.data?.imageList,
        totalPages: response.data?.totalPages,
      });

      setTimeout(() => {
        setCallApi(callApi + 1);
      }, 10000);
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };
  const getMusicGenerated = async (isThunder) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(
        `https://embed.axv.ai/api/image/?page=1&limit=20&sort_by=${-1}&user_id=${
          user && user.user_id
        }&media_type=music&status=generated`
      );

      // Update the state with the retrieved jobs data
      seteditor({
        ...editor,
        musics: response.data?.imageList,
        totalPages: response.data?.totalPages,
      });

      setTimeout(() => {
        setCallApi(callApi + 1);
      }, 10000);
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };
  const getSoundGenerated = async (isThunder) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(
        `https://embed.axv.ai/api/image/?page=1&limit=20&sort_by=${-1}&user_id=${
          user && user.user_id
        }&media_type=sound&status=generated`
      );

      // Update the state with the retrieved jobs data
      seteditor({
        ...editor,
        sounds: response.data?.imageList,
        totalPages: response.data?.totalPages,
      });

      setTimeout(() => {
        setCallApi(callApi + 1);
      }, 10000);
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };

  const getBlocksGenerated = async (isThunder) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(
        `https://embed.axv.ai/api/block/?page=1&limit=20&sort_by=${-1}&user_id=${
          user && user.user_id
        }`
      );

      // Update the state with the retrieved jobs data
      seteditor({
        ...editor,
        blocks: response.data?.blockList,
        totalPages: response.data?.totalPages,
      });

      // setTimeout(() => {
      //   setCallApi(callApi + 1);
      // }, 10000);
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };

  const getProjects = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(
      `https://embed.axv.ai/api/project?page=${
        editor.cnPageNo
      }&limit=10&user_id=${user && user.user_id}&sort_by=${-1}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          seteditor({
            ...editor,
            projects: data.projectList,
            totalPages: data?.totalPages,
          });
        }
      });
  };

  const adjustSize = () => {
    const sidebar = document.querySelector(".sidebar");
    if (w > 80) {
      sidebar.style.width = `${80}px`;
      setW(80);
    } else {
      sidebar.style.width = `${700}px`;
      setW(700);
    }
  };
  const openSidebar = () => {
    const sidebar = document.querySelector(".sidebar");

    sidebar.style.width = `${700}px`;
    setW(700);
  };

  const items = [
    {
      key: "1",
      label: (
        <div className="icons-tab">
          <AiOutlineSearch />
        </div>
      ),
      children: (
        <Search
          callApi={callApi}
          setCallApi={setCallApi}
          search={search}
          setSearch={setSearch}
        />
      ),
    },
    editor.cType === "block"
      ? null
      : {
          key: "2",
          label: (
            <div className="icons-tab">
              {" "}
              <FaMagic />
            </div>
          ),
          children: (
            <div
              style={{ marginTop: "0px" }}
              className=" card-imagine-create2 editor-create-bx"
            >
              <Imagine />
            </div>
          ),
        },
    editor.cType === "block"
      ? {
          key: "3",
          label: <AddBlock dAdd={dAdd} setdAdd={setdAdd} />,
          children: null,
        }
      : {
          key: "3",
          label: (
            <div className="icons-tab">
              <FiUpload />
            </div>
          ),
          children:
            editor.cType === "video" ? (
              <UploadVideo />
            ) : editor.cType === "image" ? (
              <UploadImage />
            ) : editor.cType === "music" ||
              editor.cType === "sound" ||
              editor.cType === "voice" ? (
              <UploadAudio />
            ) : null,
        },
  ];

  return (
    <div
      onResizeCapture={(v) => console.log(v)}
      class="sidebar left-side-container"
    >
      <div class="resizer"></div>
      <div className="left-icons-a">
        <Link to="/">
          <a className="navbar-brand" href="#">
            <img className="logo-img-bc" src={logo} alt="" />
          </a>
        </Link>
        <div className="left-icons-nn">
          <div
            className={`icon-left-ed ${
              editor.cType === "canvas" ? " active-i-l-ed" : ""
            }`}
            onClick={() => {
              seteditor({
                ...editor,
                cType: "canvas",
                totalPages: undefined,
                cnPageNo: 1,
              });
              setCallApi(callApi + 1);
              openSidebar();
            }}
          >
            <BsClipboard2Fill />
            <b>Canvas</b>
          </div>
          <div
            className={`icon-left-ed ${
              editor.cType === "video" ? " active-i-l-ed" : ""
            }`}
            onClick={() => {
              seteditor({
                ...editor,
                cType: "video",
                totalPages: undefined,
                vPageNo: 1,
              });
              setCallApi(callApi + 1);
              openSidebar();
            }}
          >
            <BsFillCameraVideoFill />
            <b>Video</b>
          </div>
          <div
            className={`icon-left-ed ${
              editor.cType === "image" ? " active-i-l-ed" : ""
            }`}
            onClick={() => {
              seteditor({
                ...editor,
                cType: "image",
                totalPages: undefined,
                iPageNo: 1,
              });
              setCallApi(callApi + 1);
              openSidebar();
            }}
          >
            <BiSolidImage />
            <b>Image</b>
          </div>
          <div
            className={`icon-left-ed ${
              editor.cType === "music" || editor.cType === "sound"
                ? " active-i-l-ed"
                : ""
            }`}
            onClick={() => {
              seteditor({
                ...editor,
                cType: "music",
                totalPages: undefined,
                sPageNo: 1,
                mPageNo: 1,
              });
              setCallApi(callApi + 1);
              openSidebar();
            }}
          >
            <FaMusic style={{ fontSize: "16px" }} />
            <b>Audio</b>
          </div>
          <div
            className={`icon-left-ed ${
              editor.cType === "voice" ? " active-i-l-ed" : ""
            }`}
            onClick={() => {
              seteditor({
                ...editor,
                cType: "voice",
                totalPages: undefined,
                voPageNo: 1,
              });
              setCallApi(callApi + 1);
              openSidebar();
            }}
          >
            <RiChatVoiceFill />
            <b>Voice</b>
          </div>
          <div
            className={`icon-left-ed ${
              editor.cType === "block" ? " active-i-l-ed" : ""
            }`}
            onClick={() => {
              setTab("1");
              seteditor({
                ...editor,
                cType: "block",
                totalPages: undefined,
                bPageNo: 1,
              });
              setCallApi(callApi + 1);
              openSidebar();
            }}
          >
            <BsBox />
            <b>Blocks</b>
          </div>

          {/* <div
            className={`icon-left-ed ${
              editor.cType === "free" ? " active-i-l-ed" : ""
            }`}
            onClick={() => seteditor({ ...editor, cType: "free" })}
          >
            <BsSoundwave />
            <b>Free</b>
          </div> */}

          <div
            className={`icon-left-ed ${
              editor.cType === "developers" ? " active-i-l-ed" : ""
            }`}
            onClick={() => {
              seteditor({ ...editor, cType: "developers" });
              openSidebar();
            }}
          >
            <BsCode />
            <b>Developers</b>
          </div>
          <div
            className={`icon-left-ed ${
              editor.cType === "account" ? " active-i-l-ed" : ""
            }`}
            onClick={() => {
              seteditor({ ...editor, cType: "account" });
              openSidebar();
            }}
          >
            <FaUser />
            <b>Account</b>
          </div>
        </div>
      </div>
      <div className="right-in-left">
        <Tabs
          defaultActiveKey={tab}
          activeKey={tab}
          items={items}
          onChange={(v) => {
            if (editor.cType === "block") {
            } else {
              setTab(v);
            }
          }}
          centered
          style={{ marginBottom: "10px" }}
        />

        {editor.cType === "account" ? <Profile /> : null}
        {editor.cType === "developers" ? <DevelopersHub /> : null}
        {editor.cType === "video" ? <Videos /> : null}
        {editor.cType === "image" ? <Images /> : null}
        {editor.cType === "canvas" ? <Projects /> : null}
        {editor.cType === "sound" || editor.cType === "music" ? (
          <div className="tags-editor-tb">
            <Tag
              onClick={() => {
                seteditor({ ...editor, cType: "music" });
                setCallApi(callApi + 1);
              }}
              className={`editor-tag-tab ${
                editor.cType === "music" ? " active-tg-wdt" : ""
              }`}
            >
              Music
            </Tag>
            <Tag
              onClick={() => {
                seteditor({ ...editor, cType: "sound" });
                setCallApi(callApi + 1);
              }}
              className={`editor-tag-tab ${
                editor.cType === "sound" ? " active-tg-wdt" : ""
              }`}
            >
              Sound
            </Tag>
          </div>
        ) : null}
        {editor.cType === "sound" ? <Sounds /> : null}
        {editor.cType === "free" ? <Free /> : null}
        {editor.cType === "block" ? <Blocks /> : null}
        {editor.cType === "music" ? <Music /> : null}
        {editor.cType === "voice" ? <Voices /> : null}
      </div>
      <div className="close-icon-ed">
        <span onClick={adjustSize}>
          {w > 80 ? <BsChevronCompactLeft /> : <BsChevronCompactRight />}
        </span>
      </div>
    </div>
  );
}
