import { Modal } from "antd";
import React, { useContext } from "react";
import SearchContext from "../../Context/SearchContext";
import UploadP from "./Upload";
import Pricing from "./Pricing";
import Term from "./Term";

export default function Onboard() {
  const { user, organization, setOrganization } = useContext(SearchContext);

  return (
    <Modal
      width={800}
      className="no-buttons-modal no-close-model black-modal pricing-modal"
      open={
        (organization && organization.onboard === "pending") ||
        (organization && organization.onboard === "pricing") ||
        (organization && organization.onboard === "upload")
      }
    >
      {organization ? (
        <div>
          {organization.onboard === "pending" ? <Term /> : null}
          {organization.onboard === "upload" ? <UploadP /> : null}
          {organization.onboard === "pricing" ? <Pricing /> : null}
        </div>
      ) : null}
    </Modal>
  );
}
