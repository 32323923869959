import React, { useContext, useEffect, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";
import { Button, Skeleton, Spin, Tooltip, message } from "antd";
import { FaExpandAlt, FaPlay } from "react-icons/fa";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { ImShrink2 } from "react-icons/im";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";

export default function Images() {
  const { editor, seteditor, user, stories, setStories } =
    useContext(SearchContext);
  const [p, setP] = useState();
  const [callApi, setCallApi] = useState(1);
  const [loading2, setLoading2] = useState(false);

  useEffect(() => {
    if (editor.iPageNo > 1 && callApi > 1) {
      getImagesGenerated();
    }
  }, [callApi]);
  const deleteTask = (row, key) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/image/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");
          const all = editor.images.filter((p) => p._id !== row._id);
          seteditor({ ...editor, images: all });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };

  const saveSelected = (f, total) => {
 
    let obj = {
      file_url: f.file_url,
      project_id: stories?.active?._id,
      quene: f.quene,
      user_id: user && user.user_id,
      media_type: "image",
      position: editor.position ,
    };

    const requestOptions = {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(`https://embed.axv.ai/api/image`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {

          let all = [...stories.data];
          all[editor.position] = data.data
          setStories({
            ...stories,
            data: all,
          });
        }
      });
  };

  const loadMore = (v, key) => {
    // console.log(
    //   v.target.scrollHeight,
    //   "sss",
    //   Math.ceil(v.target.offsetHeight + v.target.scrollTop),
    //   !loading2,editor.totalPages , editor.vPageNo
    // );
    if (
      v.target.scrollHeight ===
        Math.ceil(v.target.offsetHeight + v.target.scrollTop) &&
      editor.totalPages > editor.iPageNo &&
      !loading2
    ) {
      // console.log("load")
      seteditor({
        ...editor,
        iPageNo: editor.iPageNo + 1,
        scrollHeight: v.target.scrollHeight, // for later use for fix scrol height
      });
      setLoading2(true);
      setCallApi(callApi + 1);
    }
  };
  const getImagesGenerated = async (isThunder) => {
    try {
      // Make a GET request to the jobs API endpoint
      const response = await axios.get(
        `https://embed.axv.ai/api/image/?page=${
          editor.vPageNo
        }&limit=20&sort_by=${-1}&user_id=${
          user && user.user_id
        }&media_type=image&status=generated`
      );

      setLoading2(false);
      // Update the state with the retrieved jobs data
      let all = editor.images.concat(response.data?.imageList);
      seteditor({
        ...editor,
        images: all,
        totalPagesV: response.data?.totalPages,
      });
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching jobs data:", error);
    }
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );

  const updateValue = (obj, id, g) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/image/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {

        if (response && response.isSuccess) {
          saveSelected(g);
        }
      });
  };
  return (
    <div onScroll={loadMore} className="videos-left-ed">
      {editor.images &&
        editor.images.map((g, gi) => {
          return (
            <div
              key={gi}
              style={{
                width: p && p._id === g._id ? "100%" : "140px",
                marginRight: p && p._id === g._id ? "0px" : "10px",
                maxWidth: "290px",
                height: "auto",
              }}
              className="video-card-story"
            >
              {g.quene === "progress" ? (
                <div style={{ background: "grey", borderRadius: "5px" }}>
                  <Skeleton.Button active size={115} shape={"default"} />
                </div>
              ) : (
                <div className="video-thumbnail-img ">
                  <div
                    style={{ top: "-5px", right: "-10px" }}
                    className="show-content-icon2 show-hover"
                  >
                    <span />
                    <Tooltip title="Delete">
                      <div
                        style={{ width: "14px", height: "14px", opacity: "1" }}
                        onClick={() => {
                          deleteTask(g);
                        }}
                        className="icon-img-hover"
                      >
                        <MdClose style={{ fontSize: "14px", color: "black" }} />
                      </div>
                    </Tooltip>
                  </div>
                  <img src={g.file_url} alt="" />
                  <div className="show-content-icon2 show-hover">
                    {p && p._id === g._id ? (
                      <div onClick={() => setP()} className="icon-img-hover">
                        <ImShrink2 />
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          setP(g);
                        }}
                        className="icon-img-hover"
                      >
                        <FaExpandAlt />
                      </div>
                    )}
                    <div
                      onClick={() => {
                        seteditor({
                          ...editor,
                          activeContent: g,
                          activeType: "image",
                        });
                        if (editor.activeContent?._id) {
                          updateValue(
                            { project_id: "deleted" },
                            editor.activeContent._id,

                            g
                          );
                        } else if (stories.active) {
                          saveSelected(g);
                        }
                      }}
                      className="icon-img-hover"
                    >
                      <BsArrowRightCircleFill />
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      <div className="loadin-videos-more-c">
        {loading2 ? <Spin indicator={antIcon} /> : null}
      </div>
    </div>
  );
}
