import React, { useContext } from "react";
import { BiSolidImage } from "react-icons/bi";
import { BsBox, BsFillCameraVideoFill } from "react-icons/bs";
import { FaAdjust, FaMusic } from "react-icons/fa";
import { RiChatVoiceFill } from "react-icons/ri";
import SearchContext from "../../../../Context/SearchContext";
import { v4 as uuidv4 } from "uuid";

export default function Start() {
  const { editor, seteditor, setStories, stories } = useContext(SearchContext);

  return (
    <div className="start-mn-mx">
      <div className="center-input-u-img">
        <input
          style={{
            width: 300,
          }}
          type="text"
          placeholder="Search"
          //   onChange={(e) => setSearch(e.target.value)}
          //   value={search}
          required=""
          className="form-control border-0 shadow-sm px-4 "
        />
      </div>
      <div className="cards-bx-str">
        <div
          onClick={() => {
            let id = uuidv4();

            seteditor({
              ...editor,
              activeContent: { _id: id },
              activeType: "image",
            });
            setStories({
              ...stories,
              data: [{ _id: id }],
            });
          }}
          className="start-card-bx"
        >
          <div>
            <BiSolidImage />
            <h5>Image</h5>
          </div>
          <p>Kickstart your design journey using your favorite images.</p>
        </div>
        <div className="start-card-bx">
          <div>
            <BsFillCameraVideoFill />
            <h5>Video</h5>
          </div>
          <p>Craft your canvas from dynamic video footage.</p>
        </div>

        <div
          onClick={() =>
            seteditor({
              ...editor,
              activeContent: {},
              activeType: "audio blank",
            })
          }
          className="start-card-bx"
        >
          <div>
            <FaMusic style={{ fontSize: "20px", marginTop: "2px" }} />
            <h5>Audio</h5>
          </div>
          <p>Begin your project with immersive audio files.</p>
        </div>
        <div className="start-card-bx">
          <div>
            <RiChatVoiceFill />
            <h5>Voice</h5>
          </div>
          <p>Voice your ideas and create with vocal input.</p>
        </div>
        <div className="start-card-bx">
          <div>
            <BsBox />
            <h5>Blocks</h5>
          </div>
          <p>Build reusable components for your design workflows.</p>
        </div>
      </div>
    </div>
  );
}
