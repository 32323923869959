import { Button, Checkbox } from "antd";
import React, { useContext, useState } from "react";
import SearchContext from "../../Context/SearchContext";

export default function Term() {
  const { organization, user, setUser, setOrganization } =
    useContext(SearchContext);
  const [agree, isAgree] = useState(false);

  const updateOrganization = (key, val) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append(key, val);

    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {});
  };
  const logout = () => {
    window.localStorage.removeItem("user");
    window.location.reload();
  };
  return (
    <div>
      <div className="term-page-box-fc">
        <h4>Terms of Service Agreement for Olm AI Video Generation Platform</h4>
        <p>Last Updated: September 27, 2023</p>
        <p>
          This Terms of Service Agreement ("Agreement") is entered into by and
          between Olm ("Company," "we," "our," or "us") and the user ("User,"
          "you," or "your") of the Olm AI video generation platform
          ("Platform"). By accessing or using the Platform, you agree to comply
          with and be bound by the terms and conditions set forth in this
          Agreement.
        </p>
        <h6>1. Acceptance of Terms</h6>
        <p>
          By accessing or using the Platform, you agree to be bound by the terms
          and conditions of this Agreement. If you do not agree to these terms,
          please do not use the Platform.
        </p>
        <h6>2. Use of the Platform</h6>
        <p>
          <b> Prohibited Uses:</b> <br />
          You agree not to use the Platform for any of the following purposes:
          <ul>
            <li>
              To create, distribute, or display content that is illegal,
              harmful, threatening, abusive, harassing, defamatory, vulgar,
              obscene, or otherwise objectionable.
            </li>
            <li>
              To generate content that promotes or incites violence, hate,
              discrimination, or harm to any individual or group.
            </li>
            <li>
              To create, distribute, or display explicit sexual content or
              pornography.
            </li>
            <li>
              To infringe upon the intellectual property rights of others.
            </li>
            <li>To engage in any fraudulent or deceptive activities.</li>
            <li>To violate any applicable laws or regulations.</li>
          </ul>
        </p>
        <h6>3. Content Ownership</h6>
        <p>
          <b>User-Generated Content:</b> Any content generated by the Platform
          using your inputs or requests is owned by you. However, you grant Olm
          a non-exclusive, royalty-free, worldwide license to use, reproduce,
          distribute, and display the content for the purpose of providing and
          improving the Platform.
        </p>
        <p>
          <b>Olm Content: </b> All content, including but not limited to text,
          graphics, logos, images, and videos, provided by Olm on the Platform,
          is the property of Olm and is protected by copyright and other
          intellectual property laws.
        </p>
        <h6>4. Termination</h6>
        <p>
          Olm reserves the right to terminate or suspend your access to the
          Platform, with or without notice, for any violation of these terms or
          for any other reason.
        </p>
        <h6>5. Disclaimer of Warranties</h6>
        <p>
          The Platform is provided "as is" and "as available" without any
          warranties, expressed or implied. Olm makes no representations or
          warranties regarding the accuracy, completeness, or reliability of the
          Platform.
        </p>
        <h6>6. Limitation of Liability</h6>
        <p>
          To the extent permitted by applicable law, Olm shall not be liable for
          any indirect, incidental, special, consequential, or punitive damages,
          or any loss of profits or revenues, whether incurred directly or
          indirectly, or any loss of data, use, goodwill, or other intangible
          losses.
        </p>
        <h6>7. Governing Law</h6>
        <p>
          This Agreement is governed by and construed in accordance with the
          laws of Canada.
        </p>
        <h6>8. Changes to the Agreement</h6>
        <p>
          Olm may update or revise this Agreement at any time. The updated
          Agreement will be posted on the Platform, and your continued use of
          the Platform after such changes constitutes your acceptance of the
          revised Agreement.
        </p>
        <h6>9. Contact Information</h6>
        <p>
          If you have any questions or concerns about this Agreement, please
          contact us at <a href="mailto:support@olm.ai">support@olm.ai</a>.
        </p>
        <p>
          By using the Olm AI video generation platform, you agree to abide by
          these terms and conditions. Failure to comply with these terms may
          result in the termination of your access to the Platform.
        </p>
      </div>

      <div className="onb-m-bot">
        <div className="up-bx-mnbcs">
          <Button
            onClick={() => {
              logout();
            }}
            type="primary"
          >
            Sign Out
          </Button>

          <div style={{display:"flex" , alignItems:"center"}}>
            <div onClick={() => isAgree(!agree)} class="checkbox-n">
              <input
                checked={agree}
                id="checkbox1"
                class="checkbox__input"
                type="checkbox"
                 
                onChange={(e) => { isAgree(e.target.checked)}}
              />
              <label
                style={{ color: "white", marginRight: "20px" }}
                for="checkbox1"
                class="checkbox__label"
              >
                <span class="checkbox__custom"></span>I Agree
              </label>
            </div>
            <div style={{width:"60px"}}>
              {agree ? (
                <Button
                  onClick={() => {
                    updateOrganization("onboard", "upload");
                    setOrganization({ ...organization, onboard: "upload" });
                  }}
                >
                  Next
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
