import Feed from "./feed/Feed";
import Home from "./home/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Profile from "./profile/Profile";
import Console from "./console/Console";
import Login from "./login/Login";
import Signup from "./signup/Signup";
import { useContext, useEffect, useState } from "react";
import SearchContext from "../Context/SearchContext";
import { v4 as uuidv4 } from "uuid";
import VideoPage from "./videoPage/VideoPage";
import ShareVideo from "./share/ShareVideo";
import { FaPlay } from "react-icons/fa";
import WatchTry from "./watchTry/WatchTry";
import Story from "./story/Story";
import StoryProject from "./storyProject/StoryProject";
import Test from "./test/Test";
import Test2 from "./test copy 2/Test";
import TourOnboard from "../components/TourOnboard";
import WatchCreate from "./watchCreate/WatchCreate";
import Editor from "./editor/Editor";
import { SignedIn, SignedOut } from "@clerk/clerk-react";
import SIgnupTest from "./SIgnupTest/SIgnupTest";
import SignInTest from "./SignInTest/SignInTest";

function Pages() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    setUser,
    loginChange,
    left,
    setLeft,
    theme,
    setFormType,
    setTheme,
    setOrganization,
    setLanguage,
  } = useContext(SearchContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (u) {
      setUser(u);
      getOrganization(u);
    } else {
      if (
        window.location.pathname !== "/" &&
        window.location.pathname !== "/login" &&
        window.location.pathname !== "/signup" &&
        window.location.pathname !== "/watch/try" &&
        !window.location.pathname.includes("/share/") &&
        !window.location.pathname.includes("/profile/")
      ) {
        navigate("/login");
      }
      //   let isID = JSON.parse(window.localStorage.getItem("isID"));
      //   let user_id;
      //   if (isID) {
      //     user_id = isID.user_id;
      //   } else {
      //     user_id = uuidv4();
      //   }
      //   obj = { user_id };
      //   setUser(obj);
    }
    setLoading(false);
  }, [loginChange]);

  useEffect(() => {
    const t = window.localStorage.getItem("theme");
    const lang = window.localStorage.getItem("language");
    if (lang) {
      setLanguage(lang);
    }
    if (t) {
      setTheme(t);
    }
  }, []);

  const getOrganization = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/organization/${u.user_id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          let org = response.organization;
          setOrganization(org);
        }
      });
  };
  return loading ? (
    <div className="loading-full-page">
      <div className="spiner-main-box">
        <div className="spinner"></div> <FaPlay />
      </div>
    </div>
  ) : (
    <>
      {/* <SignedIn> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/watch" element={<Feed />} />
          <Route path="/watch2" element={<WatchCreate />} />
          {/* <Route path="/story/:id" element={<Editor />} /> */}
          <Route path="/canvas/new" element={<Editor />} />
          <Route path="/canvas/project/:id" element={<Editor />} />

          {/* <Route path="/story/:id" element={<Test />} /> */}
          <Route path="/story" element={<Story />} />
          {/* <Route path="/story/:id" element={<StoryProject />} /> */}
          <Route path="/share/:id" element={<ShareVideo />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/studio" element={<Console />} />
          <Route path="/watch/try" element={<WatchTry />} />
          <Route path="/video/:id" element={<VideoPage />} />
          <Route path="/login/test" element={<SignInTest />} />
          <Route path="/signup/test" element={<SIgnupTest />} />
        </Routes>
      {/* </SignedIn> */}
        {/* <SignedOut>
      <Routes>
          <Route path="/login" element={<SignInTest />} />
          <Route path="/signup" element={<SIgnupTest />} />
      </Routes>
        </SignedOut> */}
    </>
  );
}

export default Pages;
