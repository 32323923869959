import React, { useContext, useState } from "react";
import SearchContext from "../../../../Context/SearchContext";
import { Button, Modal, message } from "antd";
import { Link } from "react-router-dom";
import profileIcon from "../../../../images/profile-icon.png";
export default function Profile() {
  const { user, setUser, organization, setShowPrice, setOrganization } =
    useContext(SearchContext);
  const [show, setShow] = useState(false);

  const updateUser = (obj) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": user.ACCESS_TOKEN,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/consumer/${user.id}/patch`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isPatched) {
          user.name = response.patchedData.name;
          window.localStorage.setItem("user", JSON.stringify(user));
          setUser(user);
        } else {
          message.error("somehing went wrong");
        }
      });
  };
  const updateOrganization = (key, val) => {
    const user = JSON.parse(window.localStorage.getItem("user"));
    const formData = new FormData();
    formData.append(key, val);

    const requestOptions = {
      method: "PUT",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(
      `https://embed.axv.ai/api/organization/${user.user_id}?_id=${organization._id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          setOrganization(response.organizationData);
        }
      });
  };
  return (
    <div className="profile-edit-page left-dev-form">
      {organization ? (
        <div>
          <div className="form-group flex-end mb-3">
            <span>
              {organization.earn === "progress" ? (
                <Button
                  className={`editor-tag-tab active-tg-wdt`}
                  size="small"
                  type="primary"
                >
                  In Progress
                </Button>
              ) : (
                <Button
                  onClick={() => setShow(true)}
                  className={`editor-tag-tab active-tg-wdt`}
                  size="small"
                  type="primary"
                >
                  Monetize
                </Button>
              )}
            </span>
          </div>
          <div className="form-group mb-3">
            <b>Profile:</b>
            <span>
              <img
                alt=""
                className="profile-imgc"
                src={user.profile_picture ? user.profile_picture : profileIcon}
              />
            </span>
          </div>
          <div className="form-group mb-3">
            <b>Name:</b>
            <span>
              <input
                type="text"
                placeholder="Name"

                style={{marginTop:"5px"}}
                onChange={(e) => setUser({ ...user, name: e.target.value })}
                value={user.name}
                onBlur={() => updateUser({ name: user.name })}
                required=""
                className="form-control rounded-pill border-0 shadow-sm px-4 text-primary"
              />
            </span>
          </div>
          <div className="form-group mb-3">
            <b>Username:</b>
            <span>
              <input
                type="text"
                style={{marginTop:"5px"}}

                placeholder="Username"
                onChange={(e) => setUser({ ...user, username: e.target.value })}
                value={user.username}
                onBlur={() => {
                  if (user.username.length < 3) {
                    const u = JSON.parse(window.localStorage.getItem("user"));
                    setUser(u);
                    message.warning(
                      "Usernamae should be longer then 3 letters"
                    );
                  } else {
                    updateUser({ username: user.username });
                  }
                }}
                required=""
                className="form-control rounded-pill border-0 shadow-sm px-4 text-primary"
              />
            </span>
          </div>
          <div className="form-group mb-3">
            <b>Email:</b>
            <span>{user.email}</span>
          </div>
       
          <div className="form-group mb-3">
            <b>Earnings:</b>
            <span>{organization.balance}</span>
          </div>
          <div className="form-group mb-3">
            <b>Subscription:</b>
            <span>{organization.subscription}</span>
          </div>{" "}
          <div className="form-group mb-3">
            <b>Balance:</b>
            <span>${organization.reserved_compute_credits}</span>
          </div>
          <div className="space-btns-c">
            <a href="https://buy.stripe.com/dR68xFbqyfkH4TKdR4" target="blank">
              <Button
                className={`editor-tag-tab active-tg-wdt`}
                size="small"
                type="primary"
              >
                Add Credit
              </Button>{" "}
            </a>

            <Button
              className={`editor-tag-tab active-tg-wdt`}
              size="small"
              onClick={() => {
                setShowPrice(true);
              }}

              style={{margin:"0px 10px"}}
            >
              Plans
            </Button>
            <Link to="https://billing.stripe.com/p/login/00gfZz7uLeaH50Y288">
              Manage Billing
            </Link>
          </div>
        </div>
      ) : null}
      <Modal
        width={500}
        className="no-buttons-modal no-close-model black-modal "
        open={show}
        onCancel={() => setShow(false)}
      >
        <h4>Monetize your Ideas</h4>
        <p>
          Start earning money today by leveraging your visual assets across the
          Olm Network.
        </p>
        <div className="monti-m-button">
          <Button
            onClick={() => {
              updateOrganization("earn", "progress");
              setShow(false);
            }}
          >
            Continue
          </Button>
          <Button onClick={() => setShow(false)}>Cancel</Button>
        </div>
      </Modal>
    </div>
  );
}
